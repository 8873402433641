module.exports=function(scope){ return `<div id="asseco-videochat-window-video-panel">
    <button class="mdl-button mdl-button--fab mdl-button--mini-fab screen-btn">
        ${scope.screenBtn}
    </button>

    <button class="mdl-button mdl-button--fab mdl-button--mini-fab audio-btn">
        ${scope.audioBtnOn}
    </button>

    <button class="mdl-button mdl-button--fab mdl-button--mini-fab video-btn">
        ${scope.videoBtnOn}
    </button>

    ${scope.flipCamera ? `
        <button class="mdl-button mdl-button--fab mdl-button--mini-fab flip-camera-btn">
            ${scope.flipCameraBtn}
        </button>`
    : ''}

    ${scope.switchCamera ? `
        <button class="mdl-button mdl-button--fab mdl-button--mini-fab switch-camera-btn">
            ${scope.switchCameraBtn}
        </button>`
    : ''}

    <div id="asseco-videochat-overlay"></div>
    <video id="asseco-videochat-window-video-remote" autoplay="autoplay" src=""></video>
    <video id="asseco-videochat-window-video-local" autoplay="autoplay" muted="true" src=""></video>
</div>`};