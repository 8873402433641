import Svgs from 'data/Svgs';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import Utils from 'util/Utils';
import Element from 'util/Element';
import CmpMgr from 'util/ComponentManager';
import 'util/Function';
import LiveField from 'widgets/field/Field';
import LiveFilemanagerAuthAbstract from './../abstract';

//////////////////////////////////////////////
import LiveFilemanagerWindow from './../../window/Window';
//////////

///////////////////
import JSEncrypt from 'jsencrypt';
//////////

/**
 * LIVE RDS Component (not available in Asseco namespace)
 *
 * Document authorized adapter
 *
 * @private
 */
class LiveFilemanagerRds extends LiveFilemanagerAuthAbstract {
    /**
     * Step in which user is given info about certificate status
     *
     * @private {Number}
     */
    static STEP_CERT_NO_CERT = 1;

    /**
     * Step in which user is viewing user data for issuing certificate
     *
     * @private {Number}
     */
    static STEP_CERT_USER_DATA = 2;

    /**
     * Step in which user is viewing data summary for issuing certificate and confirms issuing
     *
     * @private {Number}
     */
    static STEP_CERT_CONFIRM = 3;

    /**
     * Step in which user is given issuing certificate contract preview
     *
     * @private {Number}
     */
    static STEP_CERT_CONTRACT_PREVIEW = 4;

    /**
     * Step in which user is given documents preview for signing
     *
     * @private {Number}
     */
    static STEP_DOC_PREVIEW = 5;

    /**
     * User for issuing certificate exists
     *
     * @private {Boolean} certUser
     */
    certUser;

    /**
     * Certificate dName
     *
     * @private {String} certDname
     */
    certDname;

    /**
     * User data fetched on getUserData
     *
     * @private {Object}
     */
    userData;

    /**
     * Form data collected
     *
     * @private {Object}
     */
    formData;

    /**
     * Revoke with given reason old certificate before issuing new one
     *
     * @private {String} revCert
     */
    revCert;

    /**
     * Current process step
     *
     * @private {Number} currentStep
     */
    currentStep;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id    : 'asseco-rds-window',
            title : a24n('eSignature In The Cloud')
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        super.initComponent();

        // save prefix for elements ids
        this.pf = 'asseco-rds-';
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Rds.scss');
    }

    /**
     * Show loading mask over card content
     *
     * @param {String} msg Mask message
     * @param {Boolean} hs Hide spinner
     */
    showMask(msg, hs) {
        // change window size for loading message
        this.wHeight();

        // no tools for wait message
        this.setTools([]);

        super.showMask(msg, hs);
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // set auto margin to position card to center
        Element.addClass(this.containerEl, 'asseco-margin-auto');

        this.checkCert();
    }

    /**
     * Check if user has valid certificate for signing documents
     *
     * @private
     */
    checkCert() {
        console.log('LiveFilemanagerRds::checkCert');

        this.setTitle(a24n('eSignature In The Cloud - Certificate Check'));
        this.showMask(a24n('Checking eSignature certificate possession in the cloud...'));
        Connection.sendMessage(Constants.REQ_RDS_CERT_CHECK, null, this.onCheckCert, this);
    }

    /**
     * Executed after cert check
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onCheckCert(conn, msg) {
        this.hideMask();

        var d = JSON.parse(msg.data);
        // user has no certificate
        if (! d.success) {
            // collect certificate dName if available
            if (d.data.hasOwnProperty('dName')) {
                this.certDname = d.data.dName;
                console.log('LiveFilemanagerRds::onCheckCert - certDname: ' + this.certDname);
            }
            this.noCert(parseInt(d.code, 10), d.msg);
        }
        // user has certificate
        else {
            this.certDname = d.data.dName;
            console.log('LiveFilemanagerRds::onCheckCert - certDname: ' + this.certDname);

            // check if user signed contract for issuing certificate
            if (d.data.contractSigned) {
                this.getFolderFiles();
            }
            // user must sign contract for issuing certificate
            else {
                this.getContract();
            }
        }
    }

    /**
     * Set card content if user has no valid certificate or is going to reissuing
     *
     * @param {Number} code Error code
     * @param {String} m Message
     * @private
     */
    noCert(code, m) {
        console.log('LiveFilemanagerRds::noCert - code: ' + code + ' (message: ' + m + ')');
        this.currentStep = LiveFilemanagerRds.STEP_CERT_NO_CERT;

        // change window size for loading message
        this.wHeight();

        // by default we don't do cert revoke to issue certificate
        this.revCert = null;

        // cert user don't exists if we receive code 1
        this.certUser = code !== 1;

        var msg, canIssue = false;
        switch (code)
        {
        case 0:
        case 500:
            msg = m || a24n('Unexpected error');
            break;

        case -1:
            msg = a24n('If you cancel signing contract for issued certificate, certificate will be revoked. Proceed with canceling?');
            this.revCert = 'CESSATION_OF_OPERATION';
            break;

        case 138:
            msg = m || a24n('Maximum number of wrong password is reached. Do you agree to revoke old certificate and issue new one?');
            this.revCert = 'UNABLE_TO_USE_PRIVKEY';
            canIssue = true;
            break;

        case 203:
            msg = a24n('User certificate is suspended. Please contact Contact Center for help');
            break;

        case 204:
            msg = a24n('User certificate has been revoked. Please contact Contact Center for help');
            break;

        case 300:
            msg = a24n('User certificate expired but can be renewed. Proceed with issuing certificate?');
            canIssue = true;
            break;

        default:
            msg = a24n('For signing document you need valid eSignature certificate in the cloud. Proceed with issuing certificate?');
            canIssue = true;
            break;
        }

        this.setContent(require('babel-loader!template-string-loader!./RdsNoCert.html')({
            msg: msg
        }));

        // go to standard issuing
        if (canIssue) {
            console.log('LiveFilemanagerRds::canIssue');

            this.setTools([{
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            this.setButtons([{
                text: a24n('Yes'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
                icon: Svgs.OK,
                scope: this,
                handler: this.getUserData
            }, {
                text: a24n('No'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);
        }
        // go to issuing but do revoke before issuing
        else if (this.revCert) {
            console.log('LiveFilemanagerRds::revCert');

            this.setTools([{
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.checkCert
            }]);

            this.setButtons([{
                text: a24n('Yes'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
                icon: Svgs.OK,
                scope: this,
                handler: this.revokeCert
            }, {
                text: a24n('No'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.checkCert
            }]);
        }
        // can't continue
        else {
            console.log('LiveFilemanagerRds::noAction');

            this.setTools([{
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            this.setButtons([{
                text: a24n('Close'),
                cls: this.buttonsCls + ' mdl-button--raised ',
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);
        }
    }

    /**
     * Get user data for issuing certificate
     *
     * @param {Boolean} r Force fetching new data from LIVE
     * @param {Boolean} sd Show user dialog after user data is refreshed
     * @param {Function} cb Callback to call after user data if fetched and showed
     * @private
     */
    getUserData(r, sd, cb) {
        console.log('LiveFilemanagerRds::getUserData');

        this.showMask(a24n('Fetching user data...'));
        Connection.sendMessage(Constants.REQ_GET_USER, {
            refresh: r === true
        }, this.onGetUserData.createDelegate(this, [sd, cb], true), this);
    }

    /**
     * Executed after user data is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @param {Boolean} sd Show user dialog after user data is refreshed
     * @param {Function} cb Callback to call after user data if fetched and showed
     * @private
     */
    onGetUserData(conn, msg, sd, cb) {
        console.log('LiveFilemanagerRds::onGetUserData');
        this.hideMask();

        this.currentStep = LiveFilemanagerRds.STEP_CERT_USER_DATA;

        var d = JSON.parse(msg.data);
        if (! Utils.isEmpty(d)) {
            // change window height and title
            this.wHeight(Math.min(600, window.innerHeight - 50));
            this.setTitle(a24n('eSignature In The Cloud - Certificate Issue Request'));

            // set user data fetched
            this.userData = d;

            var pf = this.pf,
                fLCls = pf + 'fLeft',
                fRCls = pf + 'fRight',

                tF = LiveField.getTextField,
                cF = LiveField.getCheckboxField;

            // add accept checkboxes if defined
            var accept = [];
            if (! Utils.isEmpty(d.RdsCertUserAccept)) {
                d.RdsCertUserAccept.forEach((a, i) => {
                    accept.push(cF({
                        id       : pf + 'accept' + i,
                        label    : a,
                        cls      : pf + 'accept-chk'
                    }));
                }, this);
            }

            this.setContent(require('babel-loader!template-string-loader!./RdsCertUser.html')({
                fName : tF({
                    id       : pf + 'fName',
                    label    : a24n('First Name'),
                    value    : d.n_given,
                    cls      : fLCls,
                    readonly : true
                }),
                lName : tF({
                    id       : pf + 'lName',
                    label    : a24n('Last Name'),
                    value    : d.n_family,
                    cls      : fRCls,
                    readonly : true
                }),
                adr : tF({
                    id       : pf + 'adr',
                    label    : a24n('Address'),
                    value    : d.adr_two_street + ' ' + (d.adr_two_street2 || '') + ', ' + d.adr_two_postalcode + ' ' + d.adr_two_locality,
                    readonly : true
                }),
                pin : tF({
                    id       : pf + 'pin',
                    label    : a24n('PIN'),
                    value    : d.tel_assistent,
                    readonly : true
                }),
                docType : tF({
                    id       : pf + 'docType',
                    label    : a24n('Document Type'),
                    value    : d.documentType || '',
                    cls      : fLCls,
                    readonly : true
                }),
                docNo : tF({
                    id       : pf + 'docNo',
                    label    : a24n('Document Number'),
                    value    : d.documentNo || '',
                    cls      : fRCls,
                    readonly : true
                }),
                email : tF({
                    id       : pf + 'email',
                    label    : a24n('Email'),
                    type     : 'email',
                    value    : d.email_home || '',
                    cls      : fLCls,
                    required : true,
                    error      : a24n('Please enter valid email')
                }),
                mob : tF({
                    id         : pf + 'mob',
                    label      : a24n('Mobile'),
                    value      : d.tel_cell_private || '',
                    cls        : fRCls,
                    required   : true,
                    error      : ! Utils.isEmpty(d.RdsMobileTip) ? d.RdsMobileTip : a24n('Value is required'),
                    inputAttrs : ! Utils.isEmpty(d.RdsMobilePattern) ? (' pattern="' + d.RdsMobilePattern + '"') : ''
                }),

                errTxtId : pf + 'errTxt',

                accept : accept.length > 0 ? ('<div id="' + pf + 'user-accept">' + accept.join('') + '</div>') : ''
            }));

            // register dynamic material design components
            ['fName', 'lName', 'pin', 'docType', 'docNo', 'adr', 'email', 'mob'].forEach(f => {
                this.cHU(this.getEl('#' + pf + f));
            }, this);

            // register accept checkboxes and attach handler
            Array.prototype.slice.call(this.containerEl.querySelectorAll('label.mdl-checkbox')).forEach(c => {
                this.cHU(c);
                c.MaterialCheckbox.inputElement_.onclick = () => {
                    this.getEl('#' + pf + 'issueBtn').disabled = this.containerEl.querySelectorAll('.mdl-checkbox input:checked').length < accept.length;
                };
            }, this);

            this.setButtons([{
                id: pf + 'issueBtn',
                text: a24n('Confirm'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
                icon: Svgs.OK,
                disabled: accept.length > 0,
                scope: this,
                handler: this.issueCert.createDelegate(this, [false])
            }, {
                text: a24n('Cancel'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            // show dialog
            if (sd === true) {
                Utils.dialog('', a24n('Data refreshed'));
            }

            // call callback if defined
            if (cb) {
                cb.call(this, d);
            }
        }
        else {
            this.setContent(a24n('Problem fetching user data'));

            this.setButtons([{
                text: a24n('Close'),
                cls: this.buttonsCls + ' mdl-button--raised',
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);
        }

        // show tool for closing window
        this.setTools([{
            icon: Svgs.REFRESH,
            tip: a24n('Refresh'),
            scope: this,
            handler: this.getUserData.createDelegate(this, [true])
        }, {
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);
    }

    /**
     * Issue certificate for signing documents
     *
     * @param {Boolean} cF Is certificate issue confirmed
     * @private
     */
    issueCert(cF = false) {
        console.log('LiveFilemanagerRds::issueCert - check data');

        var els = {
            'docType'     : '',
            'docNo'       : '',
            'email'       : '',
            'mob'         : '',
            'pass'        : '',
            'passr'       : '',
            'revokePass'  : '',
            'revokePassr' : '',
            'otp'         : ''
        };

        var req = true;

        // get reference to each el
        for (var prop in els) {
            els[prop] = this.getEl('#' + this.pf + prop + '-input');

            // checking required and valid data before certificate issue confirmation (user form)
            if (! cF) {
                if (
                    (['mob', 'email'].indexOf(prop) > -1 && Utils.isEmpty(els[prop].value)) ||
                    (['mob', 'email'].indexOf(prop) > -1 && els[prop].validity && ! els[prop].validity.valid)
                ) {
                    req = false;
                    els[prop].focus();
                    els[prop].blur();
                }
            }
            // checking required and valid data after certificate issue confirmation
            else {
                if (
                    (['otp', 'pass', 'revokePass'].indexOf(prop) > -1 && Utils.isEmpty(els[prop].value)) ||
                    (['pass', 'revokePass'].indexOf(prop) > -1 && els[prop].validity && ! els[prop].validity.valid)
                ) {
                    req = false;
                    els[prop].focus();
                    els[prop].blur();
                }
            }
        }

        // missing required fields or not valid
        if (! req) {
            console.log('LiveFilemanagerRds::issueCert - missing required or not valid data');
            return false;
        }

        // data collected from user form
        if (! cF) {
            this.formData = {
                revCert : this.revCert || '',
                docType : els.docType.value,
                docNo   : els.docNo.value,
                email   : els.email.value,
                mob     : els.mob.value
            };
        }

        // cheking data for issuing certificate
        if (cF) {
            // check if passwords match
            var p = els.pass, pr = els.passr;
            if (p.value !== pr.value) {
                this.getEl('#' + this.pf + 'passr span.mdl-textfield__error').textContent = a24n('Passwords do not match');
                pr.value = '';
                pr.focus();
                pr.blur();
                return;
            }

            // check if revoke passwords match
            var rp = els.revokePass, rpr = els.revokePassr;
            if (rp.value !== rpr.value) {
                this.getEl('#' + this.pf + 'revokePassr span.mdl-textfield__error').textContent = a24n('Passwords do not match');
                rpr.value = '';
                rpr.focus();
                rpr.blur();
                return;
            }

            // check if otp format id valid
            var otp = els.otp;
            if (otp.value.length !== 6 || ! /^[0-9]+$/.test(otp.value)) {
                this.getEl('#' + this.pf + 'otp span.mdl-textfield__error').textContent = a24n('Please enter valid OTP');
                otp.value = '';
                otp.focus();
                otp.blur();
                return;
            }

            console.log('LiveFilemanagerRds::issueCert - encrypting password...');

            this.showMask(a24n('Issuing certificate...'));
            this.rsaEncrypt(p.value, (eV) => {
                if (eV === null) {
                    Utils.dialog(a24n('Error'), a24n('Password encryption failed'));
                    return;
                }

                Connection.sendMessage(Constants.REQ_RDS_CERT_ISSUE, Utils.apply(this.formData, {
                    pass       : eV,
                    revokePass : rp.value,
                    otp        : otp.value
                }), this.onIssueCert, this);
            });
        }
        // if user for issuing certificate doesn't exists we need to create it first
        else if (! this.certUser) {
            console.log('LiveFilemanagerRds::issueCert - createUser');
            this.createUser();
        }
        // user for issuing certificate exists so go to certificate confirmation
        else {
            console.log('LiveFilemanagerRds::issueCert - confirmIssueCert');
            this.confirmIssueCert();
        }
    }

    /**
     * Create user for which certificate will be issued
     *
     * @private
     */
    createUser() {
        console.log('LiveFilemanagerRds::createUser');

        this.showMask(a24n('Creating user...'));
        Connection.sendMessage(Constants.REQ_RDS_CREATE_USER, this.formData, this.onCreateUser, this);
    }

    /**
     * Executed after user is created
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onCreateUser(conn, msg) {
        console.log('LiveFilemanagerRds::onCreateUser');
        this.hideMask();

        var d = JSON.parse(msg.data);
        // creating user failed
        if (! d.success) {
            this.onIssueCert(conn, msg);
        }
        else {
            console.log('LiveFilemanagerRds - user created');
            this.certUser = true;

            // collect certificate dName if available
            if (d.data.hasOwnProperty('dName')) {
                this.certDname = d.data.dName;
            }
            this.confirmIssueCert();
        }
    }

    /**
     * Confirm data for issuing certificate
     *
     * @param {Function} cb Callback to call after confirm data showed
     * @private
     */
    confirmIssueCert(cb) {
        console.log('LiveFilemanagerRds::confirmIssueCert');

        this.hideMask();
        this.currentStep = LiveFilemanagerRds.STEP_CERT_CONFIRM;

        // change window height and title
        this.wHeight(Math.min(860, window.innerHeight - 50));
        this.setTitle(a24n('eSignature In The Cloud - Issue Request'));

        var pf = this.pf,
            uD = this.userData,
            fD = this.formData,

            fLCls = pf + 'fLeft',
            fRCls = pf + 'fRight',

            tF = LiveField.getTextField,
            cF = LiveField.getCheckboxField;

        var accept = [];
        // add accept checkboxes if defined
        if (! Utils.isEmpty(uD.RdsCertConfirmAccept)) {
            uD.RdsCertConfirmAccept.forEach((a, i) => {
                accept.push(cF({
                    id       : pf + 'accept' + i,
                    label    : a,
                    cls      : pf + 'accept-chk'
                }));
            }, this);
        }

        var passLTip = '';
        if (! Utils.isEmpty(uD.RdsPasswordLabelTip)) {
            passLTip = Utils.getIconMarkup(Svgs.HELP, 'asseco-label-help help-password', '', '#000');
        }

        var rPassLTip = '';
        if (! Utils.isEmpty(uD.RdsRevokePasswordLabelTip)) {
            rPassLTip = Utils.getIconMarkup(Svgs.HELP, 'asseco-label-help help-rpassword', '', '#000');
        }

        this.setContent(require('babel-loader!template-string-loader!./RdsCertConfirm.html')({
            typeLabel       : a24n('Certificate type'),
            type            : uD.RdsCertConfirmInfo.certType,

            subjectLabel    : a24n('Certificate subject'),
            subject         : this.certDname,

            subjectAltLabel : a24n('Certificate subject alternative name'),
            subjectAlt      : fD.email,

            issuerLabel     : a24n('Certificate issuer'),
            issuer          : uD.RdsCertConfirmInfo.certIssuer,

            validToLabel    : a24n('Certificate Valid to'),
            validTo         : uD.RdsCertConfirmInfo.certValidTo,

            errTxtId        : pf + 'errTxt',

            msgPass : a24n('Choose password for you certificate') + passLTip +
                (! Utils.isEmpty(uD.RdsPasswordTip) ? '<span class="aseeco-rds-tip">' + uD.RdsPasswordTip + '</span>' : ''),

            pass : tF({
                id         : pf + 'pass',
                label      : a24n('Password'),
                type       : 'password',
                cls        : fLCls,
                required   : true,
                error      : ! Utils.isEmpty(uD.RdsPasswordPattern) ? a24n('Please enter valid password') : a24n('Value is required'),
                inputAttrs : ! Utils.isEmpty(uD.RdsPasswordPattern) ? (' pattern="' + uD.RdsPasswordPattern + '"') : ''
            }),

            passr : tF({
                id       : pf + 'passr',
                label    : a24n('Password Repeat'),
                type     : 'password',
                cls      : fRCls,
                required : true
            }),

            msgRevokePass : a24n('Choose password for revoking your certificate') + rPassLTip +
                (! Utils.isEmpty(uD.RdsRevokePasswordTip) ? '<span class="aseeco-rds-tip">' + uD.RdsRevokePasswordTip + '</span>' : ''),

            revokePass : tF({
                id         : pf + 'revokePass',
                label      : a24n('Password'),
                type       : 'password',
                cls        : fLCls,
                required   : true,
                error      : ! Utils.isEmpty(uD.RdsRevokePasswordPattern) ? a24n('Please enter valid password') : a24n('Value is required'),
                inputAttrs : ! Utils.isEmpty(uD.RdsRevokePasswordPattern) ? (' pattern="' + uD.RdsRevokePasswordPattern + '"') : ''
            }),

            revokePassr : tF({
                id       : pf + 'revokePassr',
                label    : a24n('Password Repeat'),
                type     : 'password',
                cls      : fRCls,
                required : true
            }),

            msgOtp : a24n('Enter OTP'),

            otp : tF({
                id       : pf + 'otp',
                label    : a24n('OTP'),
                cls      : fLCls,
                required : true
            }),

            info            : '<div style="clear: both; padding-top: 20px;">' + uD.RdsCertConfirmInfo.info + '</div>',

            accept          : accept.length > 0 ? ('<div style="padding-top: 30px;">' + accept.join('') + '</div>') : ''
        }));

        // add tooltip for password labels if defined
        if (! Utils.isEmpty(passLTip)) {
            var hEl = this.getEl('.help-password');
            hEl.id = Utils.generateUUID(5, 'id_');
            this.tips.push(Utils.tooltip(hEl.id, uD.RdsPasswordLabelTip));
        }

        if (! Utils.isEmpty(rPassLTip)) {
            var rhEl = this.getEl('.help-rpassword');
            rhEl.id = Utils.generateUUID(5, 'id_');
            this.tips.push(Utils.tooltip(rhEl.id, uD.RdsRevokePasswordLabelTip));
        }

        // register dynamic material design components
        ['pass', 'passr', 'revokePass', 'revokePassr', 'otp'].forEach(f => {
            this.cHU(this.getEl('#' + pf + f));
        }, this);

        // register accept checkboxes and attach handler
        Array.prototype.slice.call(this.containerEl.querySelectorAll('label.mdl-checkbox')).forEach(c => {
            this.cHU(c);
            c.MaterialCheckbox.inputElement_.onclick = () => {
                this.getEl('#' + pf + 'issueBtn').disabled = this.containerEl.querySelectorAll('.mdl-checkbox input:checked').length < accept.length;
            };
        }, this);

        this.setButtons([{
            id: pf + 'issueBtn',
            text: a24n('Confirm'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
            icon: Svgs.OK,
            disabled: accept.length > 0,
            scope: this,
            handler: this.issueCert.createDelegate(this, [true])
        }, {
            text: a24n('Cancel'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);

        // call callback if defined
        if (cb) {
            cb.call(this);
        }
    }

    /**
     * Executed after cert is issued
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onIssueCert(conn, msg) {
        this.hideMask();

        var d = JSON.parse(msg.data);
        // issuing certificate failed
        if (! d.success) {
            // fetch user data again to show user form and then show error
            this.confirmIssueCert(() => {
                // reference to error element
                var e = this.getEl('#' + this.pf + 'errTxt span.mdl-textfield__error');

                // reference to accept div
                var uA = this.getEl('#' + this.pf + 'user-accept');
                if (uA) {
                    Element.setStyle(uA, 'paddingTop', '40px');
                }

                switch (parseInt(d.code, 10))
                {
                case 401:
                    var o = this.getEl('#' + this.pf + 'otp-input');
                    this.getEl('#' + this.pf + 'otp span.mdl-textfield__error').textContent = a24n('Wrong OTP');
                    o.value = '';
                    o.focus();
                    o.blur();
                    break;

                case 407:
                    e.textContent = a24n('Too much consecutive wrong OTPs');
                    Element.setStyle(e, 'visibility', 'visible');
                    break;

                default:
                    e.textContent = d.msg || a24n('Unknown error');
                    Element.setStyle(e, 'visibility', 'visible');

                    // look for Chat, Videochat or Audiochat component
                    var ch = CmpMgr.getByXtype('LiveChatWindow');
                    if (! ch) {
                        ch = CmpMgr.getByXtype('LiveVideoChatWindow');
                    }
                    if (! ch) {
                        ch = CmpMgr.getByXtype('LiveAudioChatWindow');
                    }

                    // if Chat, Videochat or Audiochat component is found send RDS error signal message to agent
                    if (ch) {
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
////////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//////////////////////////////
///////////////////////////
//////////////////////////////////

////////////////////////////////////////////////////
                        if (Asseco.fsUA && Asseco.fsUA.agentSipName) {
                            Asseco.fsUA.message(Asseco.fsUA.agentSipName, JSON.stringify({
                                sender     : Asseco.fsUA.clientSipName,
                                recipients : Asseco.fsUA.agentSipName,
                                meta       : [{
                                    category : Constants.MSG_CAT_SIGNAL,
                                    name     : Constants.SIGNAL_RDSERROR,
                                    value    : d.msg || a24n('Unknown error')
                                }]
                            }));
                        }
//////////////////////////////////
                    }
                    break;
                }
            });
        }
        // issuing certificate succeeded
        else {
            // check if user signed contract for issuing certificate
            if (d.data.contractSigned) {
                this.getFolderFiles();
            }
            // user must sign contract for issuing certificate
            else {
                this.getContract();
            }
        }
    }

    /**
     * Revoke user certificate
     *
     * @private
     */
    revokeCert() {
        console.log('LiveFilemanagerRds::revokeCert');

        this.showMask(a24n('Revoking certificate...'));
        Connection.sendMessage(Constants.REQ_RDS_CERT_REVOKE, {
            revCert : this.revCert
        }, this.onRevokeCert, this);
    }

    /**
     * Executed after cert is revoked
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onRevokeCert(conn, msg) {
        this.hideMask();

        var d = JSON.parse(msg.data);
        // revoking certificate succeeded
        if (d.success) {
            this.onClose();
        }
        else {
            this.setContent(String.format(a24n('Problem revoking certificate ({0})'), d.msg));

            // show tool for closing window
            this.setTools([{
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            this.setButtons([{
                text: a24n('Close'),
                cls: this.buttonsCls + ' mdl-button--raised',
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);
        }
    }

    /**
     * Fetch contract for issung certificate for user to sign
     *
     * @private
     */
    getContract() {
        console.log('LiveFilemanagerRds::getContract');

        this.setTitle(a24n('eSignature In The Cloud - Certificate Issuing Contract'));
        this.showMask(a24n('Fetching eSignature in the cloud issuing contract...'));
        Connection.sendMessage(Constants.REQ_RDS_GET_CONTRACT, null, this.onGetContract, this);
    }

    /**
     * Executed after cert contract is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetContract(conn, msg) {
        this.hideMask();
        this.currentStep = LiveFilemanagerRds.STEP_CERT_CONTRACT_PREVIEW;

        var d = JSON.parse(msg.data);
        // fetching certificate contract preview failed
        if (! d.success) {
            this.setContent(d.msg || a24n('Unexpected error'));

            // show tool for closing window
            this.setTools([{
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            this.setButtons([{
                text: a24n('Close'),
                cls: this.buttonsCls + ' mdl-button--raised',
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);
        }
        // fetching certificate contract preview succeeded
        else {
            if (! window.matchMedia('(max-width: 700px)').matches) {
                this.setSize(Math.min(700, window.innerWidth - 100) + 'px', (window.innerHeight - 50) + 'px');
            }

            this.setContent(require('babel-loader!template-string-loader!./RdsDocuments.html')({
                previewClass  : this.pf + 'docpreview-contract',
                screenBtnIcon : Utils.getIconMarkup(this.docFullIcon),
                url           : Asseco.config.ChatServerUrl + '/server/chat-download.php?disposition=inline&downloadKey=' + d.data.contract,
                links         :
                    '<div style="padding-top: 15px;">' +
                        a24n('Issued certificate information:') +
                    '</div>' +
                    '<div style="padding: 15px; text-align: center;">' +
                        this.certDname +
                    '</div>' +
                    '<div style="text-align: center; padding-bottom: 15px;">' +
                        '<button id="' + this.pf + 'cer-download" class="mdl-button mdl-button--colored mdl-button--raised">' +
                            Utils.getIconMarkup(Svgs.DOWNLOAD) + ' ' + a24n('Download certificate') +
                        '</button>' +
                    '</div>' +
                    LiveField.getCheckboxField({
                        id       : this.pf + 'contract-accept',
                        label    : a24n('I accept issued certificate')
                    })
            }));

            // attach handler on button to download certificate
            this.getEl('#' + this.pf + 'cer-download').onclick = () => {
                Utils.download(Asseco.config.ChatServerUrl + '/server/chat-download.php?disposition=attachment&downloadKey=' + d.data.certificate, 'Certificate.crt');
            };

            // upgrade checkbox element and add onclick handler
            var chASel = 'label.mdl-checkbox[for="' + (this.pf + 'contract-accept-input') + '"]',
                chA = this.getEl(chASel);

            // upgrade accept checkbox and attach handler
            this.cHU(chA);
            chA.MaterialCheckbox.inputElement_.onclick = () => {
                this.getEl('#' + this.pf + 'signBtn').disabled = ! chA.MaterialCheckbox.inputElement_.checked;
            };

            // show tools for full screen and close window
            this.setTools([{
                icon: Svgs.FULL_SCREEN,
                scope: this,
                handler: function () {
                    Element.fullScreen(this.getEl('iframe.' + this.pf + 'docpreview-contract'));
                }
            }, {
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            this.setButtons([{
                id: this.pf + 'signBtn',
                text: a24n('Sign'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
                icon: this.signIcon,
                disabled: true,
                scope: this,
                handler: this.rdsSign.createDelegate(this, ['contract'])
            }, {
                text: a24n('Cancel'),
                cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onCancelContract
            }]);
        }
    }

    /**
     * Executed when client canceled signing contract for issuing certificate
     *
     * @private
     */
    onCancelContract() {
        console.log('LiveFilemanagerRds::onCancelContract');
        this.noCert(-1);
    }

    /**
     * Get documents and links for signing
     *
     * @private
     */
    getFolderFiles() {
        console.log('LiveFilemanagerRds::Fetching folder files (merge)');

        // change window size for loading message
        this.wHeight();

        // no tools for wait message
        this.setTools([]);

        this.setTitle(String.format(a24n('eSignature In The Cloud - {0}'), LiveFilemanagerWindow.displayFolderName(this.folder.name)));

        super.getFolderFiles();
    }

    /**
     * Executed after documents are fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetFolderFiles(conn, msg) {
        this.hideMask();
        this.currentStep = LiveFilemanagerRds.STEP_DOC_PREVIEW;

        var d = JSON.parse(msg.data),
            f = this.folder,
            c = f.conf;

        if (! d.success) {
            this.setContent(require('babel-loader!template-string-loader!./RdsNoCert.html')({
                msg: String.format(a24n('Error fetching documents ({0})'), d.data)
            }));

            // show tool for closing window
            this.setTools([{
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            return;
        }

        if (! window.matchMedia('(max-width: 700px)').matches) {
            this.setSize(Math.min(700, window.innerWidth - 100) + 'px', (window.innerHeight - 50) + 'px');
        }

        // list of links (required and optional)
        var links = [], lHtml = '', lUuid, linkReqC = [];
        if (! Utils.isEmpty(c) && ! Utils.isEmpty(c.links)) {
            if (typeof c.links === 'string') {
                c.links = JSON.parse(c.links);
            }
            links = c.links.sort((a, b) => (b.config.required_confirm ? 1 : 0) - (a.config.required_confirm ? 1 : 0));
        }
        links.forEach(l => {
            if (l.config.required_confirm) {
                lUuid = Utils.generateUUID(5, 'id_');
                linkReqC.push(lUuid);
                lHtml += '<div class="' + this.pf + 'link">' +
                            '<label class="mdl-checkbox mdl-js-checkbox" for="' + lUuid + '">' +
                                '<input type="checkbox" id="' + lUuid + '" class="mdl-checkbox__input">' +
                                '<span class="mdl-checkbox__label"><a class="asseco-link" href="' + l.config.url + '" target="_blank">' + l.name + '</a></span>' +
                            '</label>' +
                        '</div>';
            }
            else {
                lHtml += '<div class="' + this.pf + 'link"><a class="asseco-link ' + this.pf + 'link-nc" href="' + l.config.url + '" target="_blank">' + l.name + '</a></div>';
            }
        }, this);

        this.setContent(require('babel-loader!template-string-loader!./RdsDocuments.html')({
            previewClass  : this.pf + 'docpreview',
            screenBtnIcon : Utils.getIconMarkup(this.docFullIcon),
            url           : Asseco.config.ChatServerUrl + '/server/chat-download.php?disposition=inline&downloadKey=' + d.data,
            links         : lHtml
        }));

        Array.prototype.slice.call(this.containerEl.querySelectorAll('label.mdl-checkbox')).forEach(c => {
            this.cHU(c);
            c.MaterialCheckbox.inputElement_.onclick = () => {
                this.getEl('#' + this.pf + 'signBtn').disabled = this.containerEl.querySelectorAll('.' + this.pf + 'link .mdl-checkbox input:checked').length < linkReqC.length;
            };
        }, this);

        // show tools for full screen and close window
        this.setTools([{
            icon: Svgs.FULL_SCREEN,
            scope: this,
            handler: function () {
                Element.fullScreen(this.getEl('iframe.' + this.pf + 'docpreview'));
            }
        }, {
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);

        this.setButtons([{
            id: this.pf + 'signBtn',
            text: a24n('Sign'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
            icon: this.signIcon,
            scope: this,
            handler: this.rdsSign.createDelegate(this, ['documents'])
        }, {
            text: a24n('Close'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);

        if (! Utils.isEmpty(linkReqC)) {
            this.getEl('#' + this.pf + 'signBtn').disabled = true;
        }
    }

    /**
     * Sign contract or documents with certificate
     *
     * @param {String} which Which document to sign (contract/documents)
     * @private
     */
    rdsSign(which = 'contract') {
        // change window height
        this.wHeight(365);

        var pf = this.pf,
            tF = LiveField.getTextField;

        this.setContent(require('babel-loader!template-string-loader!./RdsCertInput.html')({
            errTxtId : pf + 'errTxt',

            forgetLinkId : pf + 'forgetLink',
            forgetLinkLabel : a24n('Forgot password?'),

            msg : a24n('Please enter password for you certificate and OTP'),

            pass : tF({
                id       : pf + 'pass',
                label    : a24n('Password'),
                type     : 'password',
                cls      : pf + 'fMiddle',
                required : true
            }),

            otp : tF({
                id       : pf + 'otp',
                label    : a24n('OTP'),
                cls      : pf + 'fMiddle',
                required : true
            })
        }));

        this.getEl('#' + pf + 'forgetLink').onclick = () => {
            this.noCert(138, a24n('Forgotten password. Do you agree to revoke old certificate and issue new one?'));
        };

        // register dynamic material design components
        ['pass', 'otp'].forEach(f => {
            this.cHU(this.getEl('#' + pf + f));
        }, this);

        // show tool for closing window
        this.setTools([{
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);

        this.setButtons([{
            id: pf + 'signBtn',
            text: a24n('Proceed'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
            icon: this.signIcon,
            scope: this,
            handler: this.doRdsSign.createDelegate(this, [which])
        }, {
            text: a24n('Close'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
            icon: Svgs.CLOSE,
            scope: this,
            handler: which === 'contract' ? this.onCancelContract : this.onClose
        }]);
    }

    /**
     * Make request for RDS sign
     *
     * @param {String} which Which document to sign (contract/documents)
     */
    doRdsSign(which) {
        var els = {
            'pass' : '',
            'otp'  : ''
        };

        var req = true;

        // get reference to each el
        for (var prop in els) {
            els[prop] = this.getEl('#' + this.pf + prop + '-input');

            // check required
            if (['pass', 'otp'].indexOf(prop) > -1 && Utils.isEmpty(els[prop].value)) {
                req = false;
                els[prop].focus();
                els[prop].blur();
            }
        }

        // missing required fields
        if (! req) {
            return false;
        }

        // check if otp format id valid
        var otp = els.otp;
        if (otp.value.length !== 6 || ! /^[0-9]+$/.test(otp.value)) {
            this.getEl('#' + this.pf + 'otp span.mdl-textfield__error').textContent = a24n('Please enter valid OTP');
            otp.value = '';
            otp.focus();
            otp.blur();
            return;
        }

        console.log('LiveFilemanagerRds::doRdsSign - ' + which);
        this.showMask(which === 'contract' ? a24n('Signing contract...') : a24n('Signing documents...'));

        console.log('LiveFilemanagerRds::doRdsSign - encrypting password...');
        this.rsaEncrypt(els.pass.value, (eV) => {
            if (eV === null) {
                Utils.dialog(a24n('Error'), a24n('Password encryption failed'));
                return;
            }

            Connection.sendMessage(which === 'contract' ? Constants.REQ_RDS_SIGN_CONTRACT : Constants.REQ_RDS_SIGN_DOCUMENTS, which === 'contract' ? {
                pass : eV,
                otp  : otp.value
            } : {
                path : this.folder.path,
                pass : eV,
                otp  : otp.value
            }, this.onDoRdsSign.createDelegate(this, [which], true), this);
        });
    }

    /**
     * Executed after signing contract or documents
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @param {String} which Which document was signed (contract/documents)
     * @private
     */
    onDoRdsSign(conn, msg, which = 'contract') {
        this.hideMask();

        var d = JSON.parse(msg.data);
        // signing failed
        if (! d.success) {
            // change window height
            this.wHeight(365);

            // show tool for closing window
            this.setTools([{
                icon: Svgs.CLOSE,
                scope: this,
                handler: this.onClose
            }]);

            var e = this.getEl('#' + this.pf + 'errTxt span.mdl-textfield__error'),
                p = this.getEl('#' + this.pf + 'pass-input'),
                o = this.getEl('#' + this.pf + 'otp-input');

            switch (parseInt(d.code, 10))
            {
            case 120:
                // if password is wrong also invalidate otp field
                o.value = '';
                o.focus();
                o.blur();

                this.getEl('#' + this.pf + 'pass span.mdl-textfield__error').textContent = a24n('Wrong Password');
                p.value = '';
                p.focus();
                p.blur();
                break;

            case 138:
                // too much wrong passwords - go to issue cert screen
                this.noCert(138);
                break;

                // case 309:
                //     e.textContent = a24n('Wrong or not supported contract configured');
                //     Element.setStyle(e, 'visibility', 'visible');
                //     break;

            case 401:
                this.getEl('#' + this.pf + 'otp span.mdl-textfield__error').textContent = a24n('Wrong OTP');
                o.value = '';
                o.focus();
                o.blur();
                break;

            case 407:
                e.textContent = a24n('Too much consecutive wrong OTPs');
                Element.setStyle(e, 'visibility', 'visible');
                break;

            default:
                e.textContent = d.msg || a24n('Unknown error');
                Element.setStyle(e, 'visibility', 'visible');
                break;
            }
        }
        // signing succeeded
        else {
            // change window height
            this.wHeight(280);

            switch (which)
            {
            case 'contract':
                this.setTitle(a24n('eSignature In The Cloud - Certificate Issuing Contract'));
                this.setContent(require('babel-loader!template-string-loader!./RdsContractDownload.html')({
                    msg1   : a24n('Contract for issuing certificate is signed. You can download contract by clicking "Download contract"'),
                    msg2   : a24n('You can proceed with signing the documents by clicking "Proceed"'),
                    btnTxt : Utils.getIconMarkup(Svgs.DOWNLOAD) + ' ' + a24n('Download contract')
                }));

                // attach handler on button to download signed contract
                this.getEl('#' + this.pf + 'contract-download').onclick = () => {
                    Utils.download(Asseco.config.ChatServerUrl + '/server/chat-download.php?disposition=attachment&downloadKey=' + d.data, 'Contract_signed.pdf');
                };

                // show tool for closing window
                this.setTools([{
                    icon: Svgs.CLOSE,
                    scope: this,
                    handler: this.onClose
                }]);

                this.setButtons([{
                    id: this.pf + 'signBtn',
                    text: a24n('Proceed'),
                    cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
                    icon: Svgs.OK,
                    scope: this,
                    handler: this.getFolderFiles
                }, {
                    text: a24n('Close'),
                    cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
                    icon: Svgs.CLOSE,
                    scope: this,
                    handler: this.onClose
                }]);
                break;

            case 'documents':
                if (this.onSuccess) {
                    this.onSuccess(a24n('Documents signed'), this.folder);
                }

                this.hide(true);
                break;
            }
        }
    }

    /**
     * Encrypt val with RSA public key
     *
     * @param {String} val Value to encrypt
     * @param {Function} cb Callback to call with encrypted value
     */
    rsaEncrypt(val, cb) {
        if (! cb) {
            console.warn('LiveFilemanagerRds::rsaEncrypt - no callback given!!!');
            return;
        }

///////////////////////////
        if (! Asseco.hasOwnProperty('rdsRsaPublicKey')) {
            this.getRdsRsaPublicKey(val, cb);
            return;
        }

        if (! Utils.isEmpty(Asseco.rdsRsaPublicKey)) {
            var encrypt, encrypted;
            encrypt = new JSEncrypt();
            encrypt.setPublicKey(Asseco.rdsRsaPublicKey);
            encrypted = encrypt.encrypt(val);
            if (encrypted !== false) {
                console.info('LiveFilemanagerRds::rsaEncrypt - value successfuly encrypted');
                cb.call(this, encrypted);
            } else {
                console.warn('LiveFilemanagerRds::rsaEncrypt - value not encrypted!!!');
                cb.call(this, null);
            }
        } else {
            console.warn('LiveFilemanagerRds::rsaEncrypt - value not encrypted!!!');
            cb.call(this, null);
        }
/////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////
//////////////////
    }

    /**
     * Get RDS RSA public key
     *
     * @param {String} val Value to encrypt
     * @param {Function} cb Callback to call with encrypted value
     * @private
     */
    getRdsRsaPublicKey(val, cb) {
        console.log('LiveFilemanagerRds::getRdsRsaPublicKey');
        Connection.sendMessage(Constants.REQ_RDS_RSA_PUBLIC_KEY, null, this.onGetRdsRsaPublicKey.createDelegate(this, [val, cb], true), this);
    }

    /**
     * Executed when RSA public key is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @param {String} val Value to encrypt
     * @param {Function} cb Callback to call with encrypted value
     * @private
     */
    onGetRdsRsaPublicKey(conn, msg, val, cb) {
        var d = JSON.parse(msg.data);
        Asseco.rdsRsaPublicKey = d.data;
        console.debug('LiveFilemanagerRds::onGetRdsRsaPublicKey - ' + Asseco.rdsRsaPublicKey);
        this.rsaEncrypt(val, cb);
    }
}
LiveFilemanagerRds.prototype.xtype = 'LiveFilemanagerRds';
export default LiveFilemanagerRds;
