/* global TreeMirrorClient */

import Constants from 'data/Constants';
import Connection from 'data/Connection';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import Element from 'util/Element';
import 'util/Function';
import LiveChat from './../chat/index';

/**
 * LIVE Co browsing Component - xtype: LiveCobrowsing - (not available in Asseco namespace) <br/>
 * Component is used for shareing page content and is only available during active chat with agent in LIVE
 *
 * Available only when using Asseco Toolbar
 */
class LiveCobrowsing extends LiveChat {
    /**
     * Holds reference to element for which mirroring will be initialized
     *
     * @type {HTMLElement} mirrorElement
     */
    mirrorElement;

    /**
     * Holds reference to tree mirror client
     *
     * @private {TreeMirrorClient} mirrorClient
     */
    mirrorClient;

    /**
     *
     * @private {Array} additionalMirrorClients
     */
    additionalMirrorClients;

    /**
     * Is cobrowsing started
     *
     * @private {Boolean} started
     */
    started = false;

    /**
     * Holds reference to Element representing agent pointer
     *
     * @private {HTMLElement} agentPointer
     */
    agentPointer;

    /**
     * Holds reference to Element representing agent canvas
     *
     * @private {HTMLElement} agentCanvas
     */
    agentCanvas;

    /**
     * Holds cobrowsing key (session)
     *
     * @private {String} coKey
     */
    coKey;

    /**
     * Holds cobrowsing WebSocket connection URL
     *
     * @private {String} coWsUrl
     */
    coWsUrl;

    /**
     * Holds instanceof WebSocket connection to cobrowsing server
     *
     * @private {WebSocket} coConn
     */
    coConn;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            identifier     : 'cobrowsing',
            label          : a24n('Start Co browsing'),
            icon           : 'M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z',
            renderDisabled : config.isPlugin

        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);

        this.additionalMirrorClients = [];
    }

    /**
     * Load css style for this component
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./style.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        if (this.isPlugin) {
            // add callback for web socket connection close
            Connection.addCallback(this, 'close', this.onConnectionClose, this);
        }
    }

    /**
     * Get cobrowsing key
     *
     * @private
     */
    getCobrowsingKey() {
        if (Connection.getStatus() === Connection.OPEN) {
            // get cobrowsing key
            Connection.sendMessage(Constants.REQ_GET_COBROWSING_KEY, null, this.onGetCobrowsingKey, this);
        }
    }

    /**
     * Executed after cobrowsing key is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetCobrowsingKey(conn, msg) {
        var d = JSON.parse(msg.data);

        this.coKey = d.cobrowsingKey;
        console.log('LiveCobrowsing::Got cobrowsing key: ' + this.coKey);

        this.coWsUrl = d.wsUrl;
        console.log('LiveCobrowsing::Got cobrowsing web socket URL: ' + this.coWsUrl);

        if (! window.WebSocket) {
            // Utils.toast(a24n('Browser not supported...'), 5000);
            Utils.dialog(a24n('Error'), a24n('Browser not supported...'));
            return;
        }

        // open websocket connection to cobrowsing server
        if (! this.coConn) {
            console.log('LiveCobrowsing::Opening web socket connection to: ' + this.coWsUrl);
            this.coConn = new WebSocket(this.coWsUrl);

            // bind events
            this.coConn.addEventListener('open', this.onConnOpen.createDelegate(this));
            this.coConn.addEventListener('close', this.onConnClose.createDelegate(this));
            this.coConn.addEventListener('message', this.onConnMessage.createDelegate(this));
            this.coConn.addEventListener('error', this.onConnError.createDelegate(this));
        }
    }

    /**
     * Executed on opening cobrowsing WebSocket connection
     *
     * @private
     */
    onConnOpen() {
        console.log('LiveCobrowsing::Cobrowsing Web socket connection opened');

        // send start signal with connection data

////////////////////////////////////
///////////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////////
////////////////////////////////////////////////
//////////////////
//////////////
///////////
//////////////////

////////////////////////////////////
        Asseco.fsUA.message(Asseco.fsUA.agentSipName, JSON.stringify({
            sender     : Asseco.fsUA.clientSipName,
            recipients : Asseco.fsUA.agentSipName,
            meta       : [{
                category : Constants.MSG_CAT_SIGNAL,
                name     : Constants.SIGNAL_COBROWSING_START,
                value    : JSON.stringify({
                    cobrowsingKey : this.coKey,
                    wsUrl         : this.coWsUrl
                })
            }]
        }));
//////////////////
    }

    /**
     * Executed on closing cobrowsing WebSocket connection
     *
     * @param {Event} e CloseEvent
     * @private
     */
    onConnClose(e) {
        console.log('LiveCobrowsing::Cobrowsing WebSocket connection closed: ', e);
        // ignore connection close if client requested closing connection
        // if (e.code !== 1006) {
        //     Utils.toast(String.format(a24n('Cobrowsing WebSocket connection closed {0}'), e.reason || ''));
        // }

        this.onStopMirroring();
    }

    /**
     * Executed on receiving data on cobrowsing WebSocket
     *
     * @param {Event} e MessageEvent
     * @private
     */
    onConnMessage(e) {
        var d = JSON.parse(e.data);
        switch (d.type)
        {
        case Constants.SIGNAL_COBROWSING_CONNECTED:
            this.onStartMirroring();
            break;

        case Constants.SIGNAL_COBROWSING_DISCONNECTED:
            this.onStopMirroring();
            break;

        case Constants.SIGNAL_COBROWSING:
            this.updateAgentData(d.value);
            break;
        }
    }

    /**
     * Executed on cobrowsing WebSocket error
     *
     * @param {Event} e ErrorEvent
     */
    onConnError(e) {
        console.log('LiveCobrowsing::Cobrowsing WebSocket connection error: ', e);
        // Utils.toast(a24n('Cobrowsing connection error'));
        Utils.dialog(a24n('Error'), a24n('Cobrowsing connection error'));
        this.coConn = null;
    }

    /**
     * Executed on component's Element click
     *
     * @param {Event} event ClickEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onClick(event) {
        if (this.disabled) {
            return;
        }

        if (Connection.getStatus() === Connection.OPEN) {
            if (! this.started) {
                this.getCobrowsingKey();
                // this.onStartMirroring();
            }
            else {
                this.onStopMirroring();
            }

            // // send start signal
            // Connection.send(JSON.stringify({
            //     type: 'cobrowsing',
            //     data: {
            //         category: 'SIGNAL',
            //         meta: 'COBROWSING_START'
            //     }
            // }));

            // setInterval(function () {
            //     var screenshot = document.documentElement.cloneNode(true);

            //     screenshot.style.pointerEvents = 'none';
            //     screenshot.style.overflow = 'hidden';
            //     screenshot.style.userSelect = 'none'; // Note: need vendor prefixes

            //     var blob = new Blob([screenshot.outerHTML], {type: 'text/html'});

            //     var reader = new window.FileReader();
            //     reader.readAsDataURL(blob);
            //     reader.onloadend = function () {
            //         Connection.send(JSON.stringify({
            //             type: 'cobrowsing',
            //             data: {
            //                 category: 'SIGNAL',
            //                 meta: 'COBROWSING',
            //                 message: {
            //                     type: 'clientScreenshot',
            //                     data: reader.result
            //                 }
            //             }
            //         }));
            //     };
            // }, 1000);
        }
        // else {
        //     Utils.toast(a24n('Not connected'));
        // }
    }

    /**
     * Executed on web socket close
     *
     * @param {Event} e  CloseEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onConnectionClose(e) {
        this.onStopMirroring();
        this.disable();
    }

    /**
     * Executed on start mirroring
     *
     * @private
     */
    onStartMirroring() {
        if (this.started) {
            return;
        }

        var mEl = this.mirrorElement || document;
        console.log('LiveCobrowsing::Initializing mirroring...', mEl);

        // send base data
        // eslint-disable-next-line no-useless-escape
        var cBase = this.clientBase || location.href.match(/^(.*\/)[^\/]*$/)[1];
        console.log('LiveCobrowsing::client base: ' + cBase);

        this.sendCobrowsingData({
            type: 'clientBase',
            data: cBase
        });

        // options
        if (Asseco.config.hasOwnProperty('LiveCobrowsing') && Asseco.config.LiveCobrowsing.hasOwnProperty('additionalElements'))
        {
            this.sendCobrowsingData({
                type: 'clientAdditionalElements',
                data: Asseco.config.LiveCobrowsing.additionalElements
            });
        }

        this.mirrorClient = new TreeMirrorClient(mEl, {
            ignoreElIds: [
                'asseco-toolbar',
                'asseco-audiochat-window-audio-panel',
                'asseco-videochat-window-video-panel',
                this.id + '-agentPointer',
                this.id + '-agentCanvas'
            ],

            initialize: (r, c) => {
                var data = {
                    f: 'initialize',
                    args: [r, c]
                };

                console.log('LiveCobrowsing::sending initialize data');

                // send mirror data
                this.sendCobrowsingData({
                    type: 'clientMirror',
                    data: data
                });

                // bind elements events
                this.bindElEvents(mEl);
            },

            applyChanged: (r, am, att, t) => {
                var data = {
                    f: 'applyChanged',
                    args: [r, am, att, t]
                };

                // send mirror data
                this.sendCobrowsingData({
                    type: 'clientMirror',
                    data: data
                });
            }
        });

        // add window resize listener
        window.addEventListener('resize', this.sendWindowData.createDelegate(this));
        this.sendWindowData();

        // add window scroll listener
        window.addEventListener('scroll', this.sendScrollData.createDelegate(this));
        this.sendScrollData();

        // add mouse move listener
        document.addEventListener('mousemove', this.sendMouseData.createDelegate(this));

        // bind elements events
        this.bindElEvents(mEl);

        // add agent pointer
        document.body.insertAdjacentHTML('beforeend',
            `<div id="${this.id}-agentPointer" class="agentPointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="96.344 146.692 15 22.838">
                    <path fill="#000" d="M98.984,146.692c2.167,1.322,1.624,6.067,3.773,7.298c-0.072-0.488,2.512-0.931,3.097,0c0.503,0.337,1.104-0.846,2.653,0.443c0.555,0.593,3.258,2.179,1.001,8.851c-0.446,1.316,2.854,0.135,1.169,2.619c-3.748,5.521-9.455,2.787-9.062,1.746c1.06-2.809-6.889-4.885-4.97-9.896c0.834-2.559,2.898,0.653,2.923,0.29c-0.434-1.07-2.608-5.541-2.923-6.985C96.587,150.793,95.342,147.033,98.984,146.692z"></path>
                </svg>
                <span>${a24n('Agent')}</span>
            </div>`
        );
        this.agentPointer = document.getElementById(this.id + '-agentPointer');

        Element.addClass(this.containerEl, 'is-active');
        this.started = true;

        console.log('LiveCobrowsing::Cobrowsing started');
    }

    /**
     * Executed on stop mirroring
     *
     * @private
     */
    onStopMirroring() {
        if (! this.started) {
            return;
        }

        console.log('LiveCobrowsing::Stoping cobrowsing...');

        if (this.coConn) {
            this.coConn.send(JSON.stringify({
                type: Constants.SIGNAL_COBROWSING_DISCONNECTED
            }));
            this.coConn.close();
            this.coConn = null;
        }

        // send stop signal

////////////////////////////////////
///////////////////////////////////////////////////////
////////////////////
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
//////////////
///////////
//////////////////

////////////////////////////////////
        if (Asseco.fsUA) {
            Asseco.fsUA.message(Asseco.fsUA.agentSipName, JSON.stringify({
                sender     : Asseco.fsUA.clientSipName,
                recipients : Asseco.fsUA.agentSipName,
                meta       : [{
                    category : Constants.MSG_CAT_SIGNAL,
                    name     : Constants.SIGNAL_COBROWSING_STOP
                }]
            }));
        }
//////////////////

        Element.removeClass(this.containerEl, 'is-active');
        this.started = false;

        if (this.mirrorClient) {
            this.mirrorClient.disconnect();
            this.mirrorClient = null;
        }

        this.additionalMirrorClients.forEach(function(mirrorClient){
            mirrorClient.disconnect();
        });
        this.additionalMirrorClients = [];

        window.removeEventListener('resize', this.sendWindowData.createDelegate(this));
        window.removeEventListener('scroll', this.sendScrollData.createDelegate(this));
        document.removeEventListener('mousemove', this.sendMouseData.createDelegate(this));

        if (this.agentPointer) {
            Element.removeNode(this.agentPointer);
            this.agentPointer = null;
        }

        if (this.agentCanvas) {
            Element.removeNode(this.agentCanvas);
            this.agentCanvas = null;
        }

        console.log('LiveCobrowsing::Cobrowsing ended');
    }

    /**
     * Bind elements events
     *
     * @param {HTMLElement} el
     * @private
     */
    bindElEvents(el) {
        // console.log('Well, I am here!');
        // var i;

        // // bind inputs
        // var ins = el.getElementsByTagName('input');
        // for (i = 0; i < ins.length; i++) {
        //     // ins[i].addEventListener('keyup', (ev) => {
        //     ins[i].addEventListener('input', (ev) => {
        //         ev.target.setAttribute('value', ev.target.value);
        //     });

        //     // https://stackoverflow.com/a/48528450
        //     // var descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(ins[i]), "value");
        //     // var originalSet = descriptor.set.bind(descriptor);

        //     // // define our own setter
        //     // descriptor.set = function(val) {
        //     //     this.setAttribute('value', val);
        //     //     originalSet.apply(this,arguments);
        //     // }.bind(descriptor);

        //     // Object.defineProperty(ins[i], "value", descriptor);

        //     // Object.defineProperty(ins[i], "value", {
        //     //     set: function(value){
        //     //         var descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), "value");
        //     //         descriptor.set.apply(this, arguments);
        //     //         this.setAttribute('value', value);
        //     //     }
        //     // });

        //     ins[i].addEventListener('propertychange', (ev) => {
        //         ev.target.setAttribute('value', ev.target.value);
        //     }, false);
        // }

        // var textareas = el.getElementsByTagName('textarea');
        // for (i = 0; i < textareas.length; i++) {
        //     // https://stackoverflow.com/a/48528450
        //     // var descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(textareas[i]), "value");
        //     // var originalSet = descriptor.set.bind(descriptor);

        //     // // define our own setter
        //     // descriptor.set = function(val) {
        //     //     this.setAttribute('value', val);
        //     //     originalSet.apply(this,arguments);
        //     // }.bind(descriptor);

        //     // Object.defineProperty(textareas[i], "value", descriptor);


        //     textareas[i].addEventListener('input', (ev) => {
        //         ev.target.innerHTML = ev.target.value;
        //     }, false);
        //     textareas[i].addEventListener('propertychange', (ev) => {
        //         ev.target.innerHTML = ev.target.value;
        //     }, false);
        // }

        // var selects = el.getElementsByTagName('select');
        // console.log("Selects:");
        // console.log(selects);
        // for (i = 0; i < selects.length; i++) {
        //     selects[i].addEventListener('change', (ev) => {
        //         console.log(ev);
        //         ev.target.setAttribute('value', ev.target.value);

        //         var form = ev.target.form;
        //         if (form) {
        //             var elements = Array.prototype.slice.call(form.elements);
        //             elements.forEach(function(el){
        //                 if (el !== ev.target) {
        //                     el.setAttribute('value', el.value);
        //                 }
        //             });
        //         }

        //     }, false);
        // }

        // var forms = el.getElementsByTagName('form');
        // console.log("Forms length: " + forms.length);
        // for (i = 0; i < forms.length; i++) {
        //     forms[i].addEventListener('reset', (ev) => {
        //         console.log("Reseting form...");
        //         var elements = Array.prototype.slice.call(ev.target.elements);
        //         console.log(elements);
        //         elements.forEach(function(element){
        //             element.setAttribute('value', element.value);
        //         });
        //     }, false);
        // }

        // // bind ul, div, iframe scrolls
        // // ['ul', 'div', 'iframe'].forEach((tag) => {
        // ['ul', 'div'].forEach((tag) => {
        //     var els = el.getElementsByTagName(tag);
        //     for (i = 0; i < els.length; i++) {
        //         els[i].addEventListener('scroll', (ev) => {
        //             var el = ev.target;

        //             // send element scroll data
        //             this.sendCobrowsingData({
        //                 type: 'clientElScroll',
        //                 data: {
        //                     id        : el.id,
        //                     nodeName  : el.nodeName,
        //                     className : el.className,
        //                     scroll    : el.scrollTop
        //                 }
        //             });
        //         });
        //     }
        // });

        setInterval(function(){
            var t0 = performance.now();

            var forms = Array.prototype.slice.call(el.getElementsByTagName('form'));

            forms.forEach(function(form){
                var elements = Array.prototype.slice.call(form.elements);
                elements.forEach(function(element){
                    if (element.nodeName === 'TEXTAREA') {
                        element.innerHTML = element.value;
                    } else if (element.nodeName === 'INPUT' && (element.type === 'radio' || element.type === 'checkbox')){
                        element.setAttribute('checked', element.checked);
                    } else {
                        element.setAttribute('value', element.value);
                    }
                });
            });

            var t1 = performance.now();
            if ((t1 - t0) > 50) {
                console.log('It took ' + (t1 - t0) + ' milliseconds.');
            }
        }.bind(el), 100);
    }

    /**
     * Send cobrowsing data
     *
     * @param {Object} m
     * @private
     */
    sendCobrowsingData(m) {
        if (this.coConn) {
            this.coConn.send(JSON.stringify({
                type  : Constants.SIGNAL_COBROWSING,
                value : m
            }));
        }
    }

    /**
     * Send window information to client for mirroring
     *
     * @param {Event} e ResizeEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    sendWindowData(e) {
        // send scroll data
        this.sendCobrowsingData({
            type: 'clientWindow',
            data: {width: window.innerWidth, height: window.innerHeight}
        });
    }

    /**
     * Send window scroll information to client for mirroring
     *
     * @param {Event} e ScrollEvent
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    sendScrollData(e) {
        var body = document.body,
            html = document.documentElement,

            winHeight = window.innerHeight,
            docHeight = Math.max(body.scrollHeight || 0, body.offsetHeight || 0, html.clientHeight || 0, html.scrollHeight || 0, html.offsetHeight || 0);

        var scrollTop = html.scrollTop + body.scrollTop,
            scrollHeight = docHeight - winHeight,
            scrollPercent = Math.round((scrollTop / scrollHeight) * 100);

        //console.log('window scroll: ', scrollTop, scrollHeight, scrollPercent);

        // send scroll data
        this.sendCobrowsingData({
            type: 'clientScroll',
            data: {scroll: scrollPercent}
        });
    }

    /**
     * Send mouse position information to client for mirroring
     *
     * @param {Event} e MouseMoveEvent
     * @private
     */
    sendMouseData(e) {
        e = e || window.event;
        if (e.pageX == null && e.clientX != null) {
            var doc = document.documentElement, body = document.body;

            e.pageX = e.clientX
                + (doc && doc.scrollLeft || body && body.scrollLeft || 0)
                - (doc.clientLeft || 0);

            e.pageY = e.clientY
                + (doc && doc.scrollTop || body && body.scrollTop || 0)
                - (doc.clientTop || 0);
        }

        // send scroll data
        // this.sendCobrowsingData({
        //     type: 'clientMouse',
        //     data: {pageX: e.pageX, pageY: e.pageY}
        // });
    }

    /**
     * Send current sources of present iframes
     *
     * @param {HTMLElement} el
     * @private
     */
    // sendIframeData(el) {
    //     var ifms = el.getElementsByTagName('iframe');
    //     for (var i = 0; i < ifms.length; i++) {
    //         ifms[i].setAttribute('data-src', ifms[i].contentWindow.location.href);
    //     }
    // }

    /**
     * Update data with infrmation send from agent
     *
     * @param {Object} d
     */
    updateAgentData(d) {
        switch (d.type)
        {
        case 'agentMouse':
            this.agentPointer.style.left = d.data.pageX + 'px';
            this.agentPointer.style.top = d.data.pageY + 'px';
            break;

        case 'agentCanvas':
            switch (d.data.action)
            {
            case 'create':
                this.agentCanvas = document.createElement('canvas');
                this.agentCanvas.setAttribute('width', document.body.clientWidth);
                this.agentCanvas.setAttribute('height', document.body.clientHeight);
                this.agentCanvas.setAttribute('style', 'position: fixed; top: 0; left: 0; z-index: 50500; cursor: pointer; pointer-events: none;');
                this.agentCanvas.setAttribute('id', this.id + '-agentCanvas');
                document.body.appendChild(this.agentCanvas);
                break;

            case 'remove':
                if (this.agentCanvas) {
                    Element.removeNode(this.agentCanvas);
                    this.agentCanvas = null;
                }
                break;

            case 'clear':
                if (this.agentCanvas) {
                    this.agentCanvas.getContext('2d').clearRect(0, 0, this.agentCanvas.width, this.agentCanvas.height);
                }
                break;

            case 'redraw':
                if (this.agentCanvas) {
                    var ctx = this.agentCanvas.getContext('2d'),
                        x = d.data.x,
                        y = d.data.y,
                        dr = d.data.d;

                    // Clear the canvas
                    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                    ctx.strokeStyle = '#df4b26';
                    ctx.lineJoin = 'round';
                    ctx.lineWidth = 3;

                    for (var i = 0; i < x.length; i++) {
                        ctx.beginPath();
                        if (dr[i] && i) {
                            ctx.moveTo(x[i - 1], y[i - 1]);
                        } else {
                            ctx.moveTo(x[i] - 1, y[i]);
                        }

                        ctx.lineTo(x[i], y[i]);
                        ctx.closePath();
                        ctx.stroke();
                    }
                }
                break;

            default:
            // nothing for now
            }
            break;

        case 'mirrorsInitialized':
            var kvPair = d.data;
            for (var key in kvPair) {
                var iframe = document.querySelector(kvPair[key]);
                this.bubbleIframeMouseMove(iframe);
                var self = this;

                var initializeMirror = function(){
                    var docki = this.contentWindow.document;

                    self.additionalMirrorClients.push(new TreeMirrorClient(docki, {
                        initialize: (r, c) => {
                            var data = {
                                f: 'initialize',
                                args: [r, c]
                            };

                            // send mirror data
                            self.sendCobrowsingData({
                                type: 'clientMirror',
                                data: data,
                                key: key
                            });

                            // bind elements events
                            self.bindElEvents(docki);

                            // send current iframe sources
                            // this.sendIframeData.defer(500, this, [mEl]);
                        },

                        applyChanged: (r, am, att, t) => {
                            var data = {
                                f: 'applyChanged',
                                args: [r, am, att, t]
                            };

                            // send mirror data
                            self.sendCobrowsingData({
                                type: 'clientMirror',
                                data: data,
                                key: key
                            });
                        }
                    }));
                }.bind(iframe);

                initializeMirror();

                iframe.addEventListener('load', initializeMirror);

                document.addEventListener('mousemove', this.sendMouseData.createDelegate(this));
            }
            break;

        default:
        // nothing for now
        }
    }

    /**
     * https://stackoverflow.com/a/15318321
     */
    // This example assumes execution from the parent of the the iframe
    bubbleIframeMouseMove(iframe) {
        // Save any previous onmousemove handler
        var existingOnMouseMove = iframe.contentWindow.onmousemove;

        // Attach a new onmousemove listener
        iframe.contentWindow.onmousemove = function (e) {
            // Fire any existing onmousemove listener
            if (existingOnMouseMove) existingOnMouseMove(e);

            // Create a new event for the this window
            var evt = document.createEvent('MouseEvents');

            // We'll need this to offset the mouse move appropriately
            var boundingClientRect = iframe.getBoundingClientRect();
            // Initialize the event, copying exiting event values

            // for the most part
            evt.initMouseEvent(
                'mousemove',
                true, // bubbles
                false, // not cancelable
                window,
                e.detail,
                e.screenX,
                e.screenY,
                e.clientX + boundingClientRect.left,
                e.clientY + boundingClientRect.top,
                e.ctrlKey,
                e.altKey,
                e.shiftKey,
                e.metaKey,
                e.button,
                null // no related element
            );
            // Dispatch the mousemove event on the iframe element
            iframe.dispatchEvent(evt);
        };
    }
}
LiveCobrowsing.prototype.xtype = 'LiveCobrowsing';
CmpMgr.registerXtype(LiveCobrowsing.prototype.xtype, LiveCobrowsing);
export default LiveCobrowsing;
