module.exports=function(scope){ return `<div class="asseco-mask">
    <div class="asseco-absolute-center asseco-plan-mask-message">
        <span class="planName">&#160;</span>

        <div class="asseco-plan-mask-wait">
            <span class="waitOrder"></span>
            <span class="waitExpectancy"></span>
            <span class="waitMessage"></span>
        </div>

        <div class="asseco-plan-mask-cancel">
            <a class="mdl-button mdl-button--colored">${scope.cancelText}</a>
        </div>
        ${scope.dm ? `
            <div class="asseco-plan-mask-dm">
                <a class="mdl-button mdl-button--colored mdl-button--raised">${scope.dmText}</a>
            </div>`
        : ''}
        <div class="asseco-plan-mask-status"></div>
    </div>
</div>`};