import 'util/Function';
import Constants from 'data/Constants';
import Connection from 'data/Connection';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import LiveChatWindowFS from 'components/chat/window/WindowFS';
import LiveChatVideo from './Video';

/**
 * LIVE Chat Winow Component (not available in Asseco namespace)
 *
 * @private
 */
class LiveVideoChatWindowFS extends LiveChatWindowFS {
    /**
     * Is muted signal shown to client
     *
     * @type {Boolean} showMutedSignal
     */
    showMutedSignal;

    /**
     * Holds the reference to window video component
     *
     * @private {LiveChatVideo} windowStream
     */
    windowStream;

    /**
     * Holds reference to local stream
     *
     * @private {Stream} localStream
     */
    localStream;

    /**
     * Holds reference to remote stream
     *
     * @private {Stream} remoteStream
     */
    remoteStream;

    /**
     * Holds reference to PeerConnection
     *
     * @private {PeerConnection} webRtcPeerConnection
     */
    webRtcPeerConnection;

    /**
     * Holds plan name for issued call
     *
     * @private {String} planName
     */
    planName;

    /**
     * Holds plan number for issued call
     *
     * @private {String} planNumber
     */
    planNumber;

    /**
     * Audo select camera on switch if there is only one additional camera
     * 
     * @param {Boolean} switchCameraAutoSelect
     */
    switchCameraAutoSelect;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id                     : 'asseco-videochat-window',
            title                  : a24n('Agent') + ': ' + config.agent,
            drawer                 : true,
            videoEnabled           : true,
            addVideo               : false,
            showMutedSignal        : true,
            switchCameraAutoSelect : false
        });

        // modify default window size if we are not using fixed drawer
        if (config.drawerFixed === false && ! config.hasOwnProperty('width')) {
            config.width = '600px';
        }

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Window.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        let sess = Asseco.fsSession;
        if (sess) {
            // on session by trigger disconnect signal
            sess.on('bye', () => {
                this.onSipMessageReceived({
                    body: JSON.stringify({
                        sender: Asseco.fsUA.agentSipName,
                        recipients: Asseco.fsUA.clientSipName,
                        meta: [{
                            name: Constants.SIGNAL_DISCONNECTED,
                            value: '',
                            category: Constants.MSG_CAT_SIGNAL
                        }]
                    })
                });
            });
        }

        // disable LiveChat, LiveAudioChat
        CmpMgr.updateComponents(['LiveChat', 'LiveAudioChat'], 'disable');

        // enable LiveVideoChat (for maximizing), LiveCoBrowsing, LiveScreenShare
        CmpMgr.updateComponents(['LiveVideoChat', 'LiveCobrowsing', 'LiveScreenShare'], 'enable');

        // create stream window
        this.createStreamWindow();
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        // stop media tracks
        var sess = Asseco.fsSession;
        if (sess && sess.sessionDescriptionHandler && sess.sessionDescriptionHandler.peerConnection) {
            Utils.webrtcStopMedia(sess.sessionDescriptionHandler.peerConnection);
        }

        super.beforeDestroy();

        if (this.windowStream) {
            this.windowStream.hide(true);
        }

        // enable LiveChat, LiveAudioChat, LiveVideoChat
        CmpMgr.updateComponents(['LiveChat', 'LiveAudioChat', 'LiveVideoChat'], 'enable');

        // disable LiveCobrowsing, LiveScreenShare
        CmpMgr.updateComponents(['LiveCobrowsing', 'LiveScreenShare'], 'disable');

        return true;
    }

    /**
     * Create window for stream
     *
     * @private
     */
    createStreamWindow() {
        console.log('LiveVideoChatWindowFS::createStreamWindow');
        this.windowStream = new LiveChatVideo({
            renderTo: this.getEl('.mdl-layout__content'),
            windowCmp: this
        });

        // create local stream
        var pc = Asseco.fsSession.sessionDescriptionHandler.peerConnection,
            localStream, remoteStream;

        this.webRtcPeerConnection = pc;

        // if local stream info is empty in pc ?!?!? - compose local stream of sending tracks
        if (Utils.isEmpty(pc.getLocalStreams()) && ! Utils.isEmpty(pc.getSenders())) {
            console.warn('LiveVideoChatWindowFS::using workaround for fetching local stream');
            var lMedia = new MediaStream();
            pc.getSenders().forEach((s) => {
                if (s.track) {
                    lMedia.addTrack(s.track);
                }
            });
            localStream = lMedia;
        }
        else {
            localStream = pc.getLocalStreams()[0];
        }
        this.localStream = localStream;

        // if remote stream info is empty in pc ?!?!? - compose remote stream of receiving tracks
        if (Utils.isEmpty(pc.getRemoteStreams()) && ! Utils.isEmpty(pc.getReceivers())) {
            console.warn('LiveVideoChatWindowFS::using workaround for fetching remote stream');
            var rMedia = new MediaStream();
            pc.getReceivers().forEach((r) => {
                if (r.track) {
                    rMedia.addTrack(r.track);
                }
            });
            remoteStream = rMedia;
        }
        else {
            remoteStream = pc.getRemoteStreams()[0];
        }
        this.remoteStream = remoteStream;

        this.windowStream.attachStream(localStream, remoteStream);
    }

    /**
     * Executed on receiving SIGNAL message in SIP message
     *
     * @param {Object} mData
     * @private
     */
    onFsSignalMessage(mData) {
        super.onFsSignalMessage(mData);

        var ms = mData[Constants.MSG_CAT_SIGNAL];

        if (Constants.SIGNAL_TOGGLEFRAME in ms) {
            this.windowStream.toggleFrame();
        }

        /**
         * If red frame is shown on the screen than take snapshot from LiveChat and upload it to LIVE
         * This is done to capture images with better quality
         */
        if (Constants.SIGNAL_SNAPSHOTTED in ms && this.windowStream.overlayFrameShown) {
            var data = this.windowStream.getImageFromVideo(this.windowStream.localMediaEl);
            
            Connection.sendMessage(Constants.REQ_SEND_SNAPSHOT_FS, {
                base64Image: data.replace('data:image/png;base64,', '').replace(' ', '+')
            }, this.onGetTempFileInfo, this);
        }

        if (! this.showMutedSignal) {
            return;
        }

        var sm;
        if (Constants.SIGNAL_AUDIOMUTED in ms) {
            sm = a24n('Agent audio muted');
        } else if (Constants.SIGNAL_AUDIOUNMUTED in ms) {
            sm = a24n('Agent audio unmuted');
        } else if (Constants.SIGNAL_VIDEOMUTED in ms) {
            sm = a24n('Agent video muted');
        } else if (Constants.SIGNAL_VIDEOUNMUTED in ms) {
            sm = a24n('Agent video unmuted');
        } else if (Constants.SIGNAL_SNAPSHOTTED in ms) {
            sm = a24n('Agent took video snapshot');
        }

        if (sm) {
            this.addNewMessage(require('babel-loader!template-string-loader!./../../chat/window/Agent.html')({
                agentIcon : Utils.getIconMarkup(this.agentIcon, '', '', '#000'),
                message   : '<i>' + sm + '</i>',
                time      : Utils.getLocaleTime()
            }));
        }
    }

    /**
     * Executes after getting temp file info
     * 
     * @param {*} conn 
     * @param {*} msg 
     */
    onGetTempFileInfo(conn, msg) {
        this.sendFSMessage(null, [{
            category : Constants.MSG_CAT_SIGNAL,
            name     : Constants.SIGNAL_TEMPFILE_INFO,
            value    : msg.data
        }]);
    }

    /**
     * Executed on receiving disconnected signal
     *
     * @private
     */
    onChatEnd() {
        // not connected
        if (! this.connected) {
            return;
        }

        console.log('LiveVideoChatWindowFS::onChatEnd');

        if (this.windowStream) {
            this.windowStream.showMask(a24n('Disconnected'), false);

            // stop media tracks
            let sess = Asseco.fsSession;
            if (sess && sess.sessionDescriptionHandler && sess.sessionDescriptionHandler.peerConnection) {
                Utils.webrtcStopMedia(sess.sessionDescriptionHandler.peerConnection);
            }
        }

        super.onChatEnd();
    }
}
LiveVideoChatWindowFS.prototype.xtype = 'LiveVideoChatWindow';
export default LiveVideoChatWindowFS;
