let gql = require('graphql-tag');

if (gql.gql && typeof gql.gql === 'function') {
    gql = gql.gql;
}

// const {GraphQLEnumType} = require('graphql');

// const InputMessageType = new GraphQLEnumType({
//     name: 'InputMessageType',
//     values: {
//         // The values are going to be used in the actual redis key itself
//         // i.e. analytics => recent:analytics:username
//         'message': {value: 'message'},
//         'action': {value: 'action'}
//     }
// });

const SEND_MESSAGE_MUTATION = gql`
    mutation ($msg: String!, $msgType: InputMessageType!,
        $value: String!, $buttonLink: String!,$sender: String!, $hidden: Boolean!){
        sendMessage(inputMessage: {
            sender:$sender,
            message:$msg,
            value:$value,
            buttonLink:$buttonLink,
            hidden:$hidden,
            source:"web",
            type:$msgType
        }){
            result
            error
        }
    }
`;

module.exports = {SEND_MESSAGE_MUTATION};
