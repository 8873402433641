/* global lang, sentryDSN, version, ChatServerUrl */

///////////////////////
/////////////////////////////
/////////////////////////
////////////////////
/////////////
//////////

// import polyfills
require('./lib/polyfill.js');

// include material design
require('./lib/material.js');

// include default style (it includes material, theme default values if not set)
require('./livechat.scss');

/////////////////////////////////////////////
var mutationSummary = require('exports-loader?MutationSummary!./lib/mutation-summary.js');
var treeMirror = require('exports-loader?TreeMirror,TreeMirrorClient!./lib/tree-mirror.js');
//////////

// defined Asseco global namespace
var Asseco = {};

var Localization = {
    getLang: () => {
        // lang is replaced with configured value in webpack.parts.js in build process
        var l = lang;
        if (('config' in Asseco) && ('lang' in Asseco.config) && (Asseco.config.lang in Asseco.Localization) ) {
            l = Asseco.config.lang;
        }
        return l;
    }
};

////////////////////////////////
Localization.en = require('./lang/en.json');
//////////

////////////////////////////////
Localization.hr = require('./lang/hr.json');
//////////

////////////////////////////////
Localization.pl = require('./lang/pl.json');
//////////

////////////////////////////////
Localization.sr = require('./lang/sr.json');
//////////

////////////////////////////////////
Localization.sr_cyr = require('./lang/sr_cyr.json');
//////////

// global translation function => a24n = a(ssecointernationalizatio)n
var translate = (key) => {
    var l = Localization.getLang();
    return (key in Asseco.Localization[l]) ? Asseco.Localization[l][key] : key;
};

import Utils from 'util/Utils';
import Connection from 'data/Connection';

// include widgets
import LiveCard from 'widgets/card/Card';
import LiveList from 'widgets/list/List';
// import LiveGrid from 'widgets/grid/Grid';
import LiveFab from 'widgets/fab/Fab';
import LiveField from 'widgets/field/Field';

///////////////////////////////////////
import LiveChat from 'components/chat/index';
//////////

// /// #if components.includes('LiveCobrowsing')
// import LiveCobrowsing from 'components/cobrowsing/index';
// /// #endif

//////////////////////////////////////////////
import LiveFilemanager from 'components/filemanager/index';
//////////

////////////////////////////////////////////
import LiveVideoChat from 'components/videochat/index';
//////////

////////////////////////////////////////////
import LiveAudioChat from 'components/audiochat/index';
//////////

///////////////////////////////////////////
import LiveCallback from 'components/callback/index';
//////////

//////////////////////////////////////////
import LiveMeeting from 'components/meeting/index';
//////////

/////////////////////////////////////////
import LiveSurvey from 'components/survey/index';
//////////

//////////////////////////////////////////////////
import LiveDirectMessaging from 'components/directmessaging/index';
//////////

// import LiveOnboardingWindow from 'components/onboarding/window/Window';

//////////////////////////////////////////
import LiveToolbar from 'components/livetoolbar/index';
//////////

//////////////////////////////////////////////
// Import the material-walkthrough package globaly
import MaterialWalkthrough from './lib/material-walkthrough';

import style from './livechat.scss';
MaterialWalkthrough.GUTTER = 0;
MaterialWalkthrough.MIN_SIZE = 50;
MaterialWalkthrough.TRANSITION_DURATION = 250;
MaterialWalkthrough.DEFAULT_COLOR = style.colorPrimary;
//////////

import Cookies from 'js-cookie';

Utils.apply(Asseco, {
    UUID             : Utils.generateUUID(),
    Version          : version,
    Localization     : Localization,
    Connection       : Connection,

    // export LIVE widgets
    LiveCard      : LiveCard,
    LiveList      : LiveList,
    // LiveGrid      : LiveGrid,
    LiveFab       : LiveFab,
    LiveField     : LiveField,

    // export LIVE components based on build configuration

///////////////////////////////////////////
    LiveChat            : LiveChat,
//////////////

    // /// #if components.includes('LiveCobrowsing')
    // LiveCobrowsing      : LiveCobrowsing,
    // /// #endif

//////////////////////////////////////////////////
    LiveFilemanager     : LiveFilemanager,
//////////////

////////////////////////////////////////////////
    LiveVideoChat       : LiveVideoChat,
//////////////

////////////////////////////////////////////////
    LiveAudioChat       : LiveAudioChat,
//////////////

///////////////////////////////////////////////
    LiveCallback        : LiveCallback,
//////////////

//////////////////////////////////////////////
    LiveMeeting         : LiveMeeting,
//////////////

/////////////////////////////////////////////
    LiveSurvey          : LiveSurvey,
//////////////

//////////////////////////////////////////////////////
    LiveDirectMessaging : LiveDirectMessaging,
//////////////

//////////////////////////////////////////////
    LiveToolbar         : LiveToolbar,
//////////////

    // LiveOnboardingWindow : LiveOnboardingWindow,

    // global variables gets replaces in build process
    ChatServerUrl : ChatServerUrl
});

module.exports = {
/////////////////////////////////////////////////
    MutationSummary  : mutationSummary,
    TreeMirror       : treeMirror.TreeMirror,
    TreeMirrorClient : treeMirror.TreeMirrorClient,
//////////////

//////////////////////////////////////////////////
    MW               : MaterialWalkthrough,
//////////////

    Asseco           : Asseco,
    a24n             : translate,
    Cookies          : Cookies
};
