/* global componentHandler */

import Connection from 'data/Connection';
import CmpMgr from 'util/ComponentManager';
import Utils from 'util/Utils';
import Element from 'util/Element';

/**
 * LIVE Component Class (not available in Asseco namespace) <br/>
 * Used as a base class for all other components
 */
class LiveComponent {
    /**
     * Specify id of the component's container Element
     *
     * @type {String} id
     */
    id = Utils.generateUUID(5, 'id_');

    /**
     * Specify this components plugins
     *
     * @type {Array} plugins
     */
    plugins;

    /**
     * Specify number of miliseconds for defering show
     *
     * @type {Number} deferRender
     */
    deferShow = 0;

    /**
     * Specify animation for defered show (asseco-bounceIn, asseco-zoomInDown, asseco-slideInDown)
     *
     * @type {String} showAnim
     */
    showAnim;

    /**
     * Specify duration in miliseconds in which component will show (transition from opacity 0 to 1)
     *
     * @type {Number} showDuration
     */
    showDuration = 500;

    /**
     * Specify the id of the element or DOM element that this component's container Element will be rendered into
     *
     * @type {String|HTMLElement} renderTo
     */
    renderTo;

    /**
     * Specify to render this component hidden
     *
     * @type {Boolean} renderHidden
     */
    renderHidden = false;

    /**
     * Specify to render this component disabled
     *
     * @type {Boolean} renderDisabled
     */
    renderDisabled = false;

    /**
     * Specify options for aligning component's container Element to other Element
     *
     * @type {Object} alignTo
     * @see https://github.com/yiminghe/dom-align
     */
    alignTo;

    /**
     * Specify position style to apply to component's container Element (css styles)
     *
     * @type {Object} position
     */
    position;

    /**
     * An optional extra CSS class that will be added to this component's container Element
     *
     * @type {String} cls
     */
    cls;

    /**
     * A custom style specification to be applied to this component's container Element
     *
     * @type {String|Object} style
     */
    style;

    /**
     * Holds the reference to component's container Element
     *
     * @private {HTMLElement} containerEl
     */
    containerEl;

    /**
     * Is this component rendered
     *
     * @private {Boolean} rendered
     */
    rendered = false;

    /**
     * Is this component hidden
     *
     * @private {Boolean} hidden
     */
    hidden = false;

    /**
     * Is this component disabled
     *
     * @private {Boolean} disabled
     */
    disabled = false;

    /**
     * Is this component destroyed (its container Element)
     *
     * @private {Boolean} destroyed
     */
    destroyed = false;

    /**
     * Holds template for this component (usually loaded from html file)
     *
     * @private {String} template
     */
    template;

    /**
     * Is this component used as plugin
     *
     * @private {Boolean} isPlugin
     */
    isPlugin = false;

    /**
     * Holds data for attaching handler to Element
     *
     * @private {Object} attachHandlers
     */
    attachHandlers;

    /**
     * This Component's initial configuration specification. Read-only.
     *
     * @private {Object} initialConfig
     */
    initialConfig;

    /**
     * Reference to mdl componentHandler upgradeElement function
     *
     * @private {Function} cHU
     */
    cHU;

    /**
     * List of components tooltips elements
     *
     * @private {Array}
     */
    tips;

    /**
     * constructor
     *
     * @param {Object} config
     */
    constructor(config = {}) {
        this.initialConfig = config;
        Utils.apply(this, config);

        // set reference to commonly used mdl function
        this.cHU = componentHandler.upgradeElement;

        this.tips = [];

        this.initComponent();
    }

    /**
     * Mount component
     *
     * @private
     */
    mount() {
        var xtype = this.__proto__.xtype;
        console.log(xtype + '::mount', this);

        // apply global setting from global configuration if set (overrides any client set component config)
        if (Asseco.hasOwnProperty('config') && Asseco.config.hasOwnProperty(xtype) && ! Utils.isEmpty(Asseco.config[xtype])) {
            console.log(xtype + '::apply settings from global configuration');
            Utils.apply(this, Asseco.config[xtype]);
        }

        // init plugins for this component
        if (this.plugins) {
            console.log(xtype + '::initPlugins');
            if (Array.isArray(this.plugins)) {
                for (let i = 0, len = this.plugins.length; i < len; i++) {
                    this.plugins[i] = this.initPlugin(this.plugins[i]);
                }
            } else {
                this.plugins = [this.initPlugin(this.plugins)];
            }
        }

        this.render();
    }

    /**
     * If component is used as plugin this method is called for plugin initialization
     *
     * @param {Object} cmp Reference to parent component
     * @private
     */
    init(cmp) {
        var xtype = this.__proto__.xtype;

        // if component is used as plugin apply global config on plugin init
        if (Asseco.hasOwnProperty('config') && Asseco.config.hasOwnProperty(xtype) && ! Utils.isEmpty(Asseco.config[xtype])) {
            console.log(xtype + '-plugin::apply settings from global configuration');
            Utils.apply(this, Asseco.config[xtype]);
        }

        // if component is used as plugin trigger afterRender after parent component is rendered
        cmp.afterRender = cmp.afterRender.createSequence(this.afterRender, this);
    }

    /**
     * Initialize plugin for this component
     *
     * @param {Object} p Plugin component for initialization
     * @return {LiveComponent} Initiated plugin
     * @private
     */
    initPlugin(p) {
        // we need to create plugin instance from config
        if (typeof p === 'object' && p.hasOwnProperty('xtype')) {
            p.isPlugin = true;
            p = new CmpMgr.xtypes[p.xtype](p);
        }
        // we need to create plugin instance from constructor
        else if (Utils.isEmpty(p.init)) {
            p = new p({isPlugin: true});
        }

        p.init(this);

        return p;
    }

    /**
     * Init component
     * @private
     */
    initComponent() {
        console.log(this.__proto__.xtype + '::initComponent', this);
        this.getConfig();
    }

    /**
     * Get config from server and apply it to Asseco global config
     * @private
     */
    getConfig() {
        if (Asseco.config && ! Asseco.config.hasOwnProperty('configFetched')) {
            var bI = require('browser-info')();
            console.log('BROWSER: ' + bI.name + ', ' + bI.fullVersion + ', ' + bI.os);
            console.log('VERSION: ' + Asseco.Version);

            // apply global config
            Connection.applyConfig();

            console.log('Fetching client configuration from server...');
            fetch(Asseco.ChatServerUrl + '/config/clientConfig.inc.php?uuid=' + Asseco.UUID)
                // resolve response to json
                .then((r) => {
                    return r.json();
                })
                // handle json
                .then((res) => {
                    console.log('Fetched client configuration from server: ', res);

                    if (! Utils.isEmpty(res)) {
                        // apply custom localization if exists
                        if (res.hasOwnProperty('Localization')) {
                            for (var l in res.Localization) {
                                if (res.Localization.hasOwnProperty(l) && Asseco.Localization.hasOwnProperty(l)) {
                                    Utils.apply(Asseco.Localization[l], res.Localization[l]);
                                }
                            }
                            delete res.Localization;
                        }

                        // apply custom configuration
                        Utils.apply(Asseco.config, res);
                    }
                    Asseco.config.configFetched = true;

                    this.mount();
                })
                .catch((err) => {
                    // Utils.toast(String.format(a24n('Error fetching configuration: {0}'), err.message));
                    Utils.dialog(a24n('Error'), String.format(a24n('Error fetching configuration: {0}'), err.message));

///////////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////

                    // continue with mount (no matter the problem with fetching configuration from server)
                    this.mount();
                });
        } else {
            this.mount();
        }
    }

    /**
     * Get component template
     *
     * @return {String}
     * @private
     */
    getTemplate() {
        return null;
    }

    /**
     * Get template data
     *
     * @return {Object}
     * @private
     */
    getTemplateData() {
        return {};
    }

    /**
     * Load this component style (loaded css is added to head)
     * @private
     */
    getStyle() {
    }

    /**
     * Render component
     *
     * @private
     */
    render() {
        if (this.renderTo) {
            if (this.getTemplate()) {
                var rEl = typeof this.renderTo === 'string' ? document.getElementById(this.renderTo) : this.renderTo;
                if (! rEl) {
                    console.warn('LiveComponent::Element for renderTo not found');
                    return;
                }

                rEl.insertAdjacentHTML('beforeend', this.template);
                this.rendered = true;
            } else {
                console.warn('LiveComponent::No template for render found');
            }

            // get reference to component main Element
            this.containerEl = document.getElementById(this.id);

            // position component
            if (this.alignTo) {
                throw new Error('Not implemented');

                //var domAlign = require('dom-align');
                //domAlign(this.containerEl, this.alignTo.targetNode || document, this.alignTo);
            }
            // apply position style
            else if (this.position) {
                Element.applyStyles(this.containerEl, this.position);
            }

            // import style from external css file
            this.getStyle();

            // set default width if defined
            if (! Utils.isEmpty(this.width)) {
                Element.setStyle(this.containerEl, {width: this.width});
            }

            // set default height if defined
            if (! Utils.isEmpty(this.height)) {
                Element.setStyle(this.containerEl, {height: this.height});
            }

            // apply additional class names
            if (this.cls) {
                Element.addClass(this.containerEl, this.cls);
            }

            // apply additional styles
            if (this.style) {
                Element.applyStyles(this.containerEl, this.style);
            }

            this.afterRender();

            // hide component if deferShow or renderHidden is set (set opacity to 0)
            if (this.renderHidden || (this.deferShow && parseInt(this.deferShow, 10) > 0)) {
                console.log(this.__proto__.xtype + '::hide component after render');
                this.hide();

                // if renderHidden is true then deferShow must be set to 0
                if (this.renderHidden) {
                    this.deferShow = 0;
                }

                // if deferShow is set show component (animate opacity to 1)
                if (this.deferShow && parseInt(this.deferShow, 10) > 0) {
                    console.log(this.__proto__.xtype + '::defer show component');
                    this.doDeferShow();
                }
            }
            else {
                this.afterShow();
            }
        }
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        console.log(this.__proto__.xtype + '::rendered');

        // attach specified event handler to rendered elements
        this.doAttachHandlers();

        // disable component is renderDisabled is set (needed here and not in render method because if component is
        // used as plugin render method is not called)
        if (this.renderDisabled) {
            this.disable();
        }

        // if this component has plugins apply configured cls and style config to each plugin
        if (! Utils.isEmpty(this.plugins)) {
            console.log(this.__proto__.xtype + '::afterRender - apply cls and style to each plugin');
            var pEl;
            this.plugins.forEach(p => {
                pEl = document.getElementById(p.id);
                if (pEl) {
                    // apply additional class names
                    if (p.cls) {
                        console.log(this.__proto__.xtype + '::afterRender - apply cls to plugin: ', p, p.cls);
                        Element.addClass(pEl, p.cls);
                    }

                    // apply additional styles
                    if (p.style) {
                        console.log(this.__proto__.xtype + '::afterRender - apply style to plugin: ', p, p.style);
                        Element.applyStyles(pEl, p.style);
                    }
                }
            }, this);
        }

        // register rendered component to ComponentManager
        CmpMgr.register(this.__proto__.xtype, this.id, this);

        // check for MW
        this.showMW(Utils.getFuncName(this.__proto__.xtype, 'afterRender'));
    }

    /**
     * Attach specified event handler to rendered elements
     *
     * @private
     */
    doAttachHandlers() {
        var aH = this.attachHandlers;
        if (! Utils.isEmpty(aH)) {
            var el, id;
            for (id in aH) {
                el = document.getElementById(id);
                if (el) {
                    el.addEventListener(
                        aH[id].eventType || 'click',
                        aH[id].handler.createDelegate(aH[id].scope || this, [el], true));
                }
            }
            delete this.attachHandlers;
        }
    }

    /**
     * Show component (optionaly with some duration)
     *
     * @param {Number} d Duration in which component will be shown
     */
    doDeferShow(d) {
        if (! this.rendered || ! this.containerEl) {
            return;
        }

        d = d || parseInt(this.showDuration, 10) || 0;
        if (d > 0) {
            (function () {
                if (! this.containerEl) {
                    return;
                }

                // apply style for showing component (opacity will be set through animation)
                Element.setStyle(this.containerEl, {
                    display : '',
                    zIndex  : 50000
                });

                if (! Utils.isEmpty(this.showAnim)) {
                    Element.addClass(this.containerEl, this.showAnim);
                    this.afterShow();
                }
                // animate opacity transition through given duration
                else {
                    for (var i = 0; i < 1.05; i += 0.05) {
                        setTimeout(function (x) {
                            if (this.containerEl) {
                                this.containerEl.style.opacity = x;
                            }
                            if (x === 1) {
                                this.hidden = false;
                                this.afterShow();
                            }
                        }.createDelegate(this, [i]), i * d);
                    }
                }
            }).defer(parseInt(this.deferShow, 10), this);
        }
        else {
            // apply style for showing component
            Element.setStyle(this.containerEl, {
                display : '',
                opacity : 1,
                zIndex  : 50000
            });

            this.hidden = false;
            this.afterShow();
        }
    }

    /**
     * Show component if hidden
     *
     * @param {Number} d Duration in which component will be shown
     */
    show(d = 0) {
        if (! this.rendered || ! this.hidden || this.destroyed) {
            return;
        }

        this.doDeferShow(d);
    }

    /**
     * Called after component is shown
     *
     * @private
     */
    afterShow() {
        console.log(this.__proto__.xtype + '::shown');

        // check for MW
        this.showMW(Utils.getFuncName(this.__proto__.xtype, 'afterShow'));
    }

    /**
     * Hide component if hidden (optionaly destroy after hiding component)
     *
     * @param {Boolean} destroy Specify to destroy this component and remove from DOM
     */
    hide(destroy) {
        if (! this.rendered || this.destroyed) {
            return;
        }

        this.containerEl.style.display = 'none';
        this.containerEl.style.opacity = 0;
        this.containerEl.style.zIndex = 0;

        if (destroy === true) {
            this.destroy();
        } else {
            this.hidden = true;
        }
    }

    /**
     * Enable component if disabled
     */
    enable() {
        if (! this.disabled) {
            return;
        }

        console.log(this.__proto__.xtype + '::enable');
        Element.removeClass(this.containerEl, 'is-disabled');
        this.disabled = false;
    }

    /**
     * Disable component if enabled
     */
    disable() {
        if (this.disabled) {
            return;
        }

        if (! this.containerEl) {
            this.containerEl = document.getElementById(this.id);
        }

        console.log(this.__proto__.xtype + '::disable');
        Element.addClass(this.containerEl, 'is-disabled');
        this.disabled = true;
    }

    /**
     * Add badge to this component main element
     *
     * @param {String} msg Badge message (should be max 2 characters long)
     */
    addBadge(msg) {
        if (this.containerEl) {
            this.removeBadge();
            Element.addClass(this.containerEl, 'mdl-badge mdl-badge--overlap');
            this.containerEl.setAttribute('data-badge', msg);
        }
    }

    /**
     * Remove badge from this component main element
     */
    removeBadge() {
        if (this.containerEl) {
            Element.removeClass(this.containerEl, ['mdl-badge', 'mdl-badge--overlap']);
            this.containerEl.removeAttribute('data-badge');
        }
    }

    /**
     * Get child element of container by CSS selector
     *
     * @param {String} s CSS selector
     * @param {HTMLElement} p Parent HTML element
     * @return {HTMLElement|null}
     */
    getEl(s, p) {
        if (! this.containerEl) {
            return null;
        }

        var pe = p || this.containerEl;
        return pe.querySelector(s);
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        return true;
    }

    /**
     * Destroy component (remove from DOM)
     */
    destroy() {
        if (! this.rendered || this.destroyed) {
            return;
        }

        if (this.beforeDestroy() === false) {
            return;
        }

        // destroy components plugins
        if (! Utils.isEmpty(this.plugins)) {
            this.plugins.forEach(p => {
                p.destroy();
            }, this);
        }

        console.log(this.__proto__.xtype + '::destroy');

        // remove tooltips
        (this.tips || []).forEach((t) => Element.removeNode(t));

        // remove callbacks from Connection if any
        Connection.removeCallback(this);

        // unregister rendered component to ComponentManager
        CmpMgr.unregister(this.__proto__.xtype, this.id);

        // remove HTML markup
        Element.removeNode(this.containerEl);
        delete this.containerEl;
        this.destroyed = true;
    }


    /**
     * Check of for given method Material Walkthrough is defined
     *
     * @param {String} m Method name
     */
    showMW(m) {
//////////////////////////////////////////////////////
        m = m.split('.');

        MW.guide = 'MW_' + m[0] + '_' + m[1];
        var steps = this['MW_' + m[1]];
        if (Array.isArray(steps) && Asseco.config.MW && ! Cookies.get('MW_SkipAll') && ! Cookies.get(MW.guide)) {
            console.log(this.__proto__.xtype + '::showMW - FOUND MW for: ' + MW.guide);

            // compose content for each step
            steps.forEach((s) => {
                s.content = Utils.getMWWrapup(s.contentTitle, s.contentBody);
            });

            MW.walk(steps);
            Cookies.set(MW.guide, true, {expires: 365});
        }
//////////////////

    }
}
LiveComponent.prototype.xtype = 'LiveComponent';
export default LiveComponent;
