const ApolloClient = require('apollo-client').default;
const {WebSocketLink} = require('apollo-link-ws');
const {HttpLink} = require('apollo-link-http');
const {split} = require('apollo-link');
const {getMainDefinition} = require('apollo-utilities');
const {InMemoryCache} = require('apollo-cache-inmemory');

const {SEND_MESSAGE_MUTATION} = require('./mutations');
const {GET_MESSAGES} = require('./queries');
const {SUBSCRIBE_NEW_MESSAGES} = require('./subscriptions');


class GraphQl {
    constructor(url) {
        url = url || '';
        
        const httpUrl = url.replace('wss:', 'https:').replace('ws:', 'http:');
        const socketUrl = url.replace('https:', 'wss:').replace('http:', 'ws:');

        const httpLink = new HttpLink({
            uri: httpUrl
        });
        
        // Create a WebSocket link:
        const wsLink = new WebSocketLink({
            uri: socketUrl,
            options: {
                reconnect: true
            }
        });
        
        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
            // split based on operation type
            ({query}) => {
                const {kind, operation} = getMainDefinition(query);
                return kind === 'OperationDefinition' && operation === 'subscription';
            },
            wsLink,
            httpLink,
        );
        
        this.client = new ApolloClient({
            link,
            cache: new InMemoryCache()
        });
    }

    sendMessageMutation({hidden, value, msg, buttonLink, sender, msgType}) {
        return this.client.mutate({
            mutation: SEND_MESSAGE_MUTATION, variables: {
                hidden,
                value,
                msg,
                buttonLink,
                sender,
                msgType,
                source: 'web'
            }
        });
    }

    getMessages(userId) {
        console.log('fetching all messages...');
        return this.client.query({
            variables: {userId: userId},
            query: GET_MESSAGES
        });
    }

    subscribeForNewMessages(userId) {
        console.log('subscribing for new messages...');
        return this.client.subscribe({
            variables: {userId: userId},
            query: SUBSCRIBE_NEW_MESSAGES
        });
    }
}

module.exports = GraphQl;
