let gql = require('graphql-tag');

if (gql.gql && typeof gql.gql === 'function') {
    gql = gql.gql;
}

const GET_MESSAGES = gql`
    query ($userId: String!){
        messages(userId:$userId){
            buttons{
                value
                id
                link
                description
            }
            type
            sender
            text
            carousel{
                elements{
                    description
                    title
                    imageUrl
                    button{
                        title
                        payload
                        type
                    }
                }
            }
            url{
                url
                urlType
                og{
                    title
                    image
                    description
                }
            }
            inputMessage{
                value
                source
                link
                type
                hidden
                message
            }
        }
    }
`;

module.exports = {GET_MESSAGES};
