/* global sdpIpModifier */

import Constants from 'data/Constants';
import Connection from 'data/Connection';
import 'util/String';
import 'util/Function';
import Element from 'util/Element';
import Utils from 'util/Utils';
import LiveList from 'widgets/list/List';

//////////////////////////////////////////////////
import LiveDirectMessagingNewWindow from 'components/directmessaging/new/New';
//////////

/**
 * LIVE Plans Card Component (not available in Asseco namespace)
 *
 * @private
 */
class LivePlans extends LiveList {
    /**
     * Holds configuration object for chat window
     *
     * @type {Object} winCfg
     */
    winCfg;

    /**
     * Specify media type which opened this window
     *
     * @type {String} mediaType
     */
    mediaType;

    /**
     * Specify which list to fetch (all/chat/audiochat/videochat)
     *
     * @type {String} list
     */
    list = 'all';

    /**
     * Specify chat window class to open
     *
     * @type {LiveCard} chatWindowClass
     */
    chatWindowClass;

    /**
     * Holds the number for automatic call without showing list
     *
     * @type {String} autoNumber
     */
    autoNumber;

    /**
     * Holds id of agent that needs ti be reserver
     *
     * @type {String} reservedAgent
     */
    reservedAgent;

    /**
     * Holds id of plan we issued call
     *
     * @private {String} planId
     */
    planId;

    /**
     * Holds name of plan we issued call
     *
     * @private {String} planName
     */
    planName;

    /**
     * Holds number of plan we issued call
     *
     * @private {String} planNumber
     */
    planNumber;

    /**
     * Holds FS login data
     *
     * @private {Object} fsLoginData
     */
    fsLoginData;

    /**
     * Preserve SIP UA agent on closing window
     *
     * @private {Boolean} fsUAPreserveOnClose
     */
    fsUAPreserveOnClose;

    /**
     * Chat info message recevied (in case we get it before CONNECTED signal)
     *
     * @private {Object} chatInfoMsg
     */
    chatInfoMsg;

    /**
     * Holds list of turn urls to request
     *
     * @private {Array} turnRequestList
     */
    turnRequestList;

    /**
     * Holds list of turn servers
     *
     * @private {Array} turnIceServers
     */
    turnIceServers;

    /**
     * constructor
     * @param {Object} config
     */
    constructor(config) {
        config = config || {};

        // apply default config if not specified
        Utils.applyIf(config, {
            id            : 'asseco-plans-window',
            title         : a24n('Available topics'),
            enableRefresh : false
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./Plans.scss');
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        // ensure css class for asseco group list
        Element.addClass(this.containerEl, 'asseco-list-plans');
    }

    /**
     * Executed before component is destroyed (return false to cancel Destroy)
     *
     * @return {Boolean}
     * @private
     */
    beforeDestroy() {
        super.beforeDestroy();

        // disconnect from FS
        if (! this.fsUAPreserveOnClose && ! Utils.isEmpty(Asseco.fsUA)) {
            // stop media tracks
            var sess = Asseco.fsSession;
            if (sess && sess.sessionDescriptionHandler && sess.sessionDescriptionHandler.peerConnection) {
                Utils.webrtcStopMedia(sess.sessionDescriptionHandler.peerConnection);
            }

            if (Asseco.fsUA) {
                console.log('LivePlans::Closing SIP UA...');
                Asseco.fsUA.stop();
                Asseco.fsUA = null;
            }
        }

        return true;
    }

    /**
     * Get list of plans
     */
    fetchList() {
        super.fetchList();

        if (this.list !== 'all') {
            this.showMask();

            console.log('LivePlans::Fetching list');
            Connection.sendMessage(Constants.REQ_GET_PLAN_LIST, {
                channel : this.mediaType,
                list    : this.list
            }, this.onFetchList, this);
        }
        // else {
        //     Utils.toast(a24n('Unexpected param'));
        // }
    }

    /**
     * Executed after list of skill groups is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onFetchList(conn, msg) {
        var ps = JSON.parse(msg.data);

        // do auto call to given number if plan number exist in received list
        if (! Utils.isEmpty(ps) && Array.isArray(ps) && ! Utils.isEmpty(this.autoNumber)) {
            var auto = false;
            ps.some(function (p) {
                if (p.to === this.autoNumber) {
                    auto = true;
                    console.log('LivePlans::Do auto call to: ' + (this.mediaType + '_' + p.id + '_' + p.to));
                    this.onItemClick.defer(50, this, [null, {id: '__' + (this.mediaType + '_' + p.id + '_' + p.to), name : p.description}]);
                    return true;
                }
            }, this);

            // if auto number is not found show list
            if (! auto) {
                this.addPlans(ps);
            }
        } else {
            this.addPlans(ps);
        }
        this.hideMask();
    }

    /**
     * Show loading mask for call in progress
     *
     * @param {String} msg Mask message
     * @param {String} bTxt Button text
     * @param {Function} cb Callback to call on button click
     * @param {Object} s Scope in which to execute callback function
     * @param {Boolean} showLoader Display waiting loader
     */
    showPlanMask(msg, bTxt, cb, s, showLoader = true) {
        this.setTitle(a24n('Please wait...'));

        var showDm = typeof LiveDirectMessagingNewWindow !== 'undefined'
                 && Asseco.customer
                 && Asseco.config.LiveDirectMessaging
                 && Asseco.config.LiveDirectMessaging.hasOwnProperty('enabled')
                 && Asseco.config.LiveDirectMessaging.enabled;

        this.setContent(require('babel-loader!template-string-loader!./PlansMask.html')({
            message    : msg,
            cancelText : bTxt || a24n('Cancel'),
            dm         : showDm,
            dmText     : a24n('Leave a message')
        }));

        if(!showLoader) this.getEl('.asseco-mask').className = 'asseco-missing-media-mask'; // in case livechatmods.js is used, this will remove loader if media is missing

        var c = this.getEl('.asseco-plan-mask-cancel > a');
        if (c && cb) {
            c.onclick = cb.createDelegate(s || this, [c], true);
        }

        var dm = this.getEl('.asseco-plan-mask-dm > a');
        if (dm) {
            dm.onclick = this.onLeaveMessage.createDelegate(this);
        }
    }

    /**
     * Executed on Leave a message button
     *
     * @private
     */
    onLeaveMessage() {
        new LiveDirectMessagingNewWindow({
            onSuccess: () => {
                this.onCancelCallPlan.defer(1000, this);
            }
        });
    }

    /**
     * Show fetched plans list
     *
     * @param {Array|Object} ps Item to be added to plans list
     */
    addPlans(ps) {
        // if we didn't get array we asume its object with error message
        if (! Array.isArray(ps)) {
            this.listEl.innerHTML = ps.msg || a24n('Unknown error');
            return;
        }

        // no plans fetched
        if (Utils.isEmpty(ps)) {
            this.noPlans();
            return;
        }

        // for audiochat list we use same media type as for videochat but we use different filter for groups
        var list = this.list === 'audiochat' ? 'videochat' : this.list;

        var psItms = [], day = new Date().getDay(), wI;
        ps.forEach(p => {
            wI = p.hasOwnProperty('workingTime') && p.workingTime.hasOwnProperty(day) ? p.workingTime[day] : null;
            psItms.push({
                id   : list.toUpperCase() + '_' + p.id + '_' + p.to,
                name : String.format(
                    '{0}{1}',
                    p.description,
                    p.working ? '' : (wI ? String.format(' (' + a24n('Not working') + ' {0}-{1})', wI.from, wI.until) : '')
                ),
                itemCls: p.working ? '' : ' disabled '
            });
        }, this);

        // add plans to list
        this.addItem(psItms);
    }

    /**
     * Executed if no plans are fetched
     *
     * @private
     */
    noPlans() {
        var mE = '';
        if (typeof LiveDirectMessagingNewWindow !== 'undefined' && Asseco.customer) {
            mE = '<div class="asseco-absolute-center asseco-plan-mask-dm"><a class="mdl-button mdl-button--colored mdl-button--raised">' + a24n('Leave a message') + '</a></div>';
        }

        this.listEl.innerHTML = a24n('No available topics') + mE;

        var dm = this.getEl('.asseco-mask-message .asseco-plan-mask-dm > a');
        if (dm) {
            dm.onclick = this.onLeaveMessage.createDelegate(this);
        }
    }

    /**
     * Executed on list item click
     * (issue call to choosen service)
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} el Clicked HTML Element
     * @private
     */
    onItemClick(e, el) {
        console.log('LivePlans::Issuing call to plan: ', el);
        if (Connection.getStatus() === Connection.OPEN) {
            this.planName = el.name || el.querySelector('span.asseco-list-item-label').innerHTML;
            this.planNumber = el.id.split('__')[1].split('_')[2];

            if (this.mediaType === Constants.MEDIA_TYPE_VIDEOCHAT) { 

                const constraints = window.constraints = { //asking for permission on video and audio
                    audio: true,
                    video: true
                };

                var self = this;
                navigator.mediaDevices.getUserMedia(constraints) 
                    .then(function() {
                        console.log('LivePlans::Media Devices available');
                        self.showPlanMask(self.planName, a24n('Cancel'), self.onCancelCallPlan, self);
                        self.getFSLoginData();
                    })
                    .catch(function(err) {
                        self.handleMediaDevicesError(err.name);
                    });
            } else {
//////////////////////////////////////////////////////
                Connection.sendMessage(Constants.REQ_BOT_IS_IMPLEMENTED, {
                    mediaType : this.mediaType,
                    type      : 'plan',
                    id        : el.id.split('__')[1].split('_')[1]
                }, this.onGetBotIsImplemented, this);
/////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////
//////////////////////////
            }
        }
        // else {
        //     Utils.toast(a24n('Not connected'));
        // }
    }

    handleMediaDevicesError(err){
        var pMsg;

        switch(err){
        case 'NotAllowedError':
            console.log('LivePlans::Media Devices Permission Denied');
            this.showMessage(a24n('Unable to access user media. Please check your browser permissions'), a24n('Cancel'), this.onCancelCallPlan, false);
            pMsg = this.getEl('.planName');
            pMsg.style.lineHeight = '1.3';
            break;

        case 'NotFoundError':
            this.findMissingDevices();
            break;

        default:
            console.log('LivePlans::Media devices error: ' + err);
            this.showMessage(a24n('Unable to acquire media device'), a24n('Cancel'), this.onCancelCallPlan, false);
            pMsg = this.getEl('.planName');
            pMsg.style.lineHeight = '1.3';
            break;    
        } 
    }

    findMissingDevices(){
        try {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                console.log('LivePlans::enumerateDevices() not supported.');
                return;
            }

            var self = this;
            var missMediaStr = '';

            // List cameras, speakers and microphones.
            navigator.mediaDevices.enumerateDevices()
                .then(function(devices) {
                    
                    console.log('LivePlans::Available devices: ', devices);
                    
                    var videoIn = false, audioIn = false, audioOut = false; //assume we don't have camera, microphone or speakers

                    //if media is blocked (permission denied), it is listed but deviceId is empty string
                    for (let i = 0; i !== devices.length; ++i) { 
                        switch(devices[i].kind){
                        case 'videoinput':
                            videoIn = true;
                            break;
                        case 'audioinput':
                            audioIn = true;
                            break;
                        case 'audiooutput':
                            audioOut = true;
                            break;
                        }      
                    }

                    if(!videoIn) missMediaStr += a24n('camera') + ', ';
                    if(!audioIn) missMediaStr += a24n('microphone') + ', ';
                    if(!audioOut) missMediaStr += a24n('speakers') + ', ';

                    missMediaStr = missMediaStr.substring(0, missMediaStr.length - 2); //remove last space and comma

                    console.log('LivePlans::Missing Media Devices');
                    self.showMessage(a24n('Missing user media source'), a24n('Cancel'), self.onCancelCallPlan, false);
                    var msgEl = self.getEl('.planName');
                    msgEl.insertAdjacentHTML('beforeend', '<span class="planName" style="font-size:17px; padding:15px;">' +  missMediaStr + '</span>');
                })
                .catch(function(err) {
                    console.log('LivePlans:: failed to request a list of the available media input and output devices. Error ' + err.name + ': ' + err.message);
                });
        } catch (e) {
            console.log('LivePlans:: enumerateDevices failed with exception: ' + e.message);
        }
    }

    /**
     * Display message in a plan mask
     * 
     * @param {String} msg Message message to display 
     * @param {String} bTxt Button text
     * @param {Function} cb Callback to call on button click
     * @param {Boolean} showLoader Display waiting loader
     */
    showMessage(msg, bTxt, cb, showLoader = true){
        this.showPlanMask('', a24n(bTxt), cb, this, showLoader);
        var masMsgEl = this.getEl('.asseco-plan-mask-message');
        if (masMsgEl) {
            // find element in which plan name is displayed
            var pN = masMsgEl.querySelector('.planName');
            if (pN) {
                pN.textContent = a24n(msg);
            }
        }
    }

//////////////////////////////////////////
    /**
     * Exectued after bot implementation data are fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetBotIsImplemented(conn, msg) {
        var d = JSON.parse(msg.data);
        if (d.success) {
            this.hide();

            // show botchat window
            new this.chatWindowClass(Utils.applyIf({
                initMessage    : '',
                agent          : a24n('LiveBot'),
                planName       : this.planName,
                planNumber     : this.planNumber,
                isBot          : true,
                callAgentFn    : this.callAgentDirectly.createDelegate(this),
                destroyPlansFn : this.destroy.createDelegate(this),
                botData        : d
            }, this.winCfg || {}));
        } else {
            this.callAgentDirectly();
        }
    }

    /**
     * Initate call to agent
     *
     * @private
     */
    callAgentDirectly() {
        this.show();
        this.showPlanMask(this.planName, a24n('Cancel'), this.onCancelCallPlan, this);
        this.getFSLoginData();
    }
//////////////

    /**
     * Requst FS login data
     *
     * @private
     */
    getFSLoginData() {
        console.log('LivePlans::Requesting FS login data (' + this.mediaType + ')...');

        var d = {
            channel : this.mediaType,
            list    : this.list
        };

        // for chat we need to send destination
        if (this.mediaType === Constants.MEDIA_TYPE_CHAT) {
            d.dest = this.planNumber;
        }

        if (! Utils.isEmpty(this.reservedAgent)) {
            d.reservedAgent = this.reservedAgent;
        }

        Connection.sendMessage(Constants.REQ_GET_FS_LOGIN, d, this.onGetFSLoginData, this);
    }

    /**
     * Executed after FS login data are fected
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetFSLoginData(conn, msg) {
        if (Utils.isEmpty(msg.data)) {
///////////////////////////////////
///////////////////////////////////////////////////////
////////////////////////
///////////////////////
//////////////////////

            this.showMask(a24n('Cannot connect to FS'), true);
            return;
        }

        this.fsLoginData = JSON.parse(msg.data);

        // if we are issuing videochat we need to resolve TURN first
        if (this.mediaType === Constants.MEDIA_TYPE_VIDEOCHAT && ! Utils.isEmpty(Asseco.config.TurnUrl)) {
            this.getWebRtcGetTurnInfo(true);
        }
        // we can init FS
        else {
            this.initFS.defer(250, this);
        }
    }

    /**
     * Initialite WebSocket connection to FS
     *
     * @private
     */
    initFS() {
        console.log('LivePlans::initFS');

        var SIP = require('sip.js');

        console.log('LivePlans::FS login data: ', this.fsLoginData);

        // get TURN servers
        var t = this.turnIceServers || [];
        console.log('LivePlans::initFS - turnIceServers: ', t);

        // get STUN servers
        var s = Asseco.config.hasOwnProperty('StunUrl') ? Asseco.config.StunUrl : [{urls: 'stun:stun.l.google.com:19302'}];
        console.log('LivePlans::initFS - stunIceServers: ', s);

        // save SIP UA reference to Asseco global namespace
        Asseco.fsUA = new SIP.UA({
            uri: this.fsLoginData._id + '@' + this.fsLoginData.domain,
            displayName: Asseco.displayName,
            authorizationUser: this.fsLoginData._id,
            password: this.fsLoginData.pw,
            registerExpires: 86400,
            transportOptions: {
                wsServers: this.fsLoginData.wsServers,
                maxReconnectionAttempts: 1
            },
            autostart: true,
            autostop: false,
            traceSip: true,
            log: {
                level: 'debug'
            },
            sessionDescriptionHandlerFactoryOptions: {
                peerConnectionOptions: {
                    iceCheckingTimeout: 1500,
                    rtcConfiguration: {
                        iceServers: t.concat(s),
                        iceTransportPolicy: Asseco.config.hasOwnProperty('IcePolicy') ? Asseco.config.IcePolicy : 'all',
                        iceCandidatePoolSize: Asseco.config.hasOwnProperty('IceCandidatePoolSize') ? parseInt(Asseco.config.IceCandidatePoolSize, 10) : 0
                    }
                }
            }
        });

        // modify SIP UA to disable web socket reconnect
        Asseco.fsUA.transport.reconnect = () => {
            var t = Asseco.fsUA.transport;
            t.logger.warn('reconnect DISABLED');
            t.ua.onTransportError(t);
        };

        // save client sip name to sip UA
        Asseco.fsUA.clientSipName = this.fsLoginData._id + '@' + this.fsLoginData.domain;

        // attach listener for FS registered
        Asseco.fsUA.on('registered', this.onFsRegistered.createDelegate(this));

        // attach listener for FS registrationFailed
        Asseco.fsUA.on('registrationFailed', this.onFsRegistrationFailed.createDelegate(this));

        // attach listener for FS disconnected
        Asseco.fsUA.on('disconnected', this.onFsDisconnected.createDelegate(this));

        // attach listener for FS message
        Asseco.fsUA.on('message', this.onFsMessage.createDelegate(this));

        var masMsgEl = this.getEl('.asseco-plan-mask-message');
        if (masMsgEl) {
            // find element in which plan name is displayed
            var pN = masMsgEl.querySelector('.planName');
            if (pN) {
                pN.textContent = this.planName;
            }

            // find element in which wait order is displayed
            var wO = masMsgEl.querySelector('.waitOrder');
            if (wO) {
                wO.innerHTML = Utils.getSpinner();
            }
        }
    }

    /**
     * Executed on SIP UA registered
     *
     * @private
     */
    onFsRegistered() {
        // for videochat we must initiate session by using INVITE
        if (this.mediaType === Constants.MEDIA_TYPE_VIDEOCHAT) {
            var modifiers = [];

            //sdpIpModifier modifier variable is global var set from webpack
            if (!Utils.isEmpty(Asseco.config.sdpIpModifierLocalIP) || (typeof sdpIpModifier !== 'undefined' && sdpIpModifier)) {
                var m;
                if (Utils.isEmpty(Asseco.config.sdpIpModifierLocalIP)) {
                    m = sdpIpModifier;
                } else {
                    m = [Asseco.config.sdpIpModifierLocalIP, Asseco.config.sdpIpModifierPublicIP];
                }
                console.log('Creating SDP modifier: ', m);
                
                var mF = function (description) {
                    description.sdp = description.sdp.replace(new RegExp(m[0], 'g'), m[1]);
                    return Promise.resolve(description);
                };
                modifiers.push(mF);
            }

            // reference to div element in which we will show some connection establishing statuses
            var dStatus = this.getEl('.asseco-plan-mask-status');

            // create session by issuing INVITE
            Asseco.fsSession = Asseco.fsUA.invite('sip:' + this.planNumber + '@' + this.fsLoginData.domain, {
                sessionDescriptionHandlerOptions: {
                    constraints: {
                        audio: true,
                        video: this.list === 'videochat'
                    }
                }
            }, modifiers);

            var masMsgEl = this.getEl('.asseco-plan-mask-message'), pN;
            if (masMsgEl) {
                // find element in which plan name is displayed
                pN = masMsgEl.querySelector('.planName');
            }

            /**
             * Attach listeners to SIP Session
             */
            var sess = Asseco.fsSession;
            sess.on('progress', (r) => {
                console.log('SIP session progress, resp: ', r);
                dStatus.textContent = a24n('Session in progress...');
            });
            sess.on('accepted', function (d) {
                console.log('SIP Session accepted, data: ', d);
                dStatus.textContent = a24n('Session accepted');
            });
            sess.on('rejected', (r, c) => {
                console.log('SIP Session rejected, resp: ', r, ', cause: ', c);
                this.showMessage(String.format(a24n('Session rejected ({0})'), c), a24n('Try again'), this.onError);

///////////////////////////////////////
///////////////////////////////////////////////////////////
/////////////////////////////
///////////////////////////////
///////////////////
//////////////////////////
            });
            sess.on('failed', (r, c) => {
                console.log('SIP Session failed, resp: ', r, ', cause: ', c);
                dStatus.textContent = String.format(a24n('Session failed ({0})'), c);

///////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////
///////////////////////////////
///////////////////
//////////////////////////
            });
            sess.on('terminated', (m, c) => {
                console.log('SIP Session terminated, msg: ', m, ', cause: ', c);
                dStatus.textContent = String.format(a24n('Session terminated ({0})'), c);
            });
            sess.on('cancel', () => {
                console.log('SIP Session canceled');
                dStatus.textContent = a24n('Session canceled');
            });
            sess.on('bye', (r) => {
                console.log('SIP Session bye, req: ', r);
                if (pN) {
                    pN.textContent = a24n('Session ended');
                }
            });
            sess.on('SessionDescriptionHandler-created', (sessH) => {
                /**
                 * Attach listeners to SIP Session Description Handler
                 */
                sessH.on('userMediaRequest', (c) => {
                    console.log('Session Description Handler, userMediaRequest: ', c);
                    dStatus.textContent = a24n('Requesting user media...');
                });
                sessH.on('userMedia', (s) => {
                    console.log('Session Description Handler, userMedia: ', s);
                    dStatus.textContent = a24n('User media aquired');
                });
                sessH.on('userMediaFailed', (e) => {
                    console.log('Session Description Handler, userMediaFailed: ', e);
                    this.showMessage(a24n('Unable to acquire media device'), a24n('Try again'), this.onError);
                });
            });
        }
    }

    /**
     * Executed on SIP UA registeration failed
     *
     * @param {String} c Casuse
     * @param {Object} r Response
     * @private
     */
    onFsRegistrationFailed(c, r) {
        console.log('SIP Registration Failed: ', c);

        this.showPlanMask('', a24n('Try again'), this.onError, this);

        var masMsgEl = this.getEl('.asseco-plan-mask-message');
        if (masMsgEl) {
            // find element in which plan name is displayed
            var pN = masMsgEl.querySelector('.planName');
            if (pN) {
                pN.textContent = a24n('Registration failed');
            }

///////////////////////////////////
//////////////////////////////////////////////////////////
/////////////////////////
///////////////////////////
///////////////
//////////////////////
        }
    }

    /**
     * Executed on SIP UA connection failure
     *
     * @param {SIP.Transport} t
     * @private
     */
    onFsDisconnected(t) {
        var SIP = require('sip.js');
        t = t.transport;
        if (t.ua.error === SIP.UA.C.NETWORK_ERROR) {
            if (Asseco.fsUA) {
                console.log('LivePlans::Closing SIP UA...');
                Asseco.fsUA.stop();
                Asseco.fsUA = null;
            }
            this.showMask(a24n('Cannot connect to FS') + '<br/><br/>' + require('autolinker').link(t.server.ws_uri.replace('ws', 'http'), {stripPrefix: false}), true);
            return;
        }
    }

    /**
     * Executed on message received via FS
     *
     * @param {Object} msg
     * @private
     */
    onFsMessage(msg) {
        var d = JSON.parse(msg.body);
        // message received from service
        if (d.hasOwnProperty('info')) {
            this.onFsServiceMessage(d);
        }
        // message received from LIVE/agent
        else if (d.hasOwnProperty('sender') && d.hasOwnProperty('meta')) {
            this.onFsAgentMessage(d);
        }
    }

    /**
     * Executed on receiving service message from SIP UA
     *
     * @param {Object} d
     * @private
     */
    onFsServiceMessage(d) {
        var masMsgEl = this.getEl('.asseco-plan-mask-message');

        if (d.info.hasOwnProperty('working') && ! d.info.working) {
            if (Asseco.fsUA) {
                console.log('LivePlans::Closing SIP UA...');
                Asseco.fsUA.stop();
                Asseco.fsUA = null;
            }

            var mE = '';
            if (typeof LiveDirectMessagingNewWindow !== 'undefined' && Asseco.customer) {
                mE = `<div class="asseco-absolute-center asseco-plan-mask-dm">
                        <a class="mdl-button mdl-button--colored mdl-button--raised">${a24n('Leave a message')}</a>
                    </div>`;
            }

            this.showMask(a24n('You called in non working time') + mE, true);

            var dm = this.getEl('.asseco-mask-message .asseco-plan-mask-dm > a');
            if (dm) {
                dm.onclick = this.onLeaveMessage.createDelegate(this);
            }
        }
        if (d.info.hasOwnProperty('queueorder') && masMsgEl && !this.hasWaitMessage) {
            // find element in which wait order is displayed
            var wO = masMsgEl.querySelector('.waitOrder');
            if (wO) {
                wO.textContent = d.info.queueorder;
                var tTxt = String.format(a24n('You are currently {0} in line'), d.info.queueorder);

                // set tooltip for wait order
                if (Utils.isEmpty(wO.id)) {
                    let wOId = Utils.generateUUID(5, 'id_');
                    wO.id = wOId;
                    wO.style.cursor = 'pointer';

                    this.tips.push(Utils.tooltip(wOId, tTxt));
                }
                // update tooltip
                else {
                    document.querySelector('.mdl-tooltip[for=' + wO.id + ']').innerHTML = tTxt;
                }
            }
        }
        if (d.info.hasOwnProperty('queuetime') && masMsgEl && !this.hasWaitMessage) {
            // find element in which wait expectancy is displayed
            var wE = masMsgEl.querySelector('.waitExpectancy');
            if (wE) {
                var t = parseInt(d.info.queuetime, 10),
                    m = '0' + Math.floor(t / 60) ,
                    s = '0' + (t - m * 60);

                wE.textContent = m.substr(-2) + 'm ' + s.substr(-2) + 's';

                // set tooltip for wait expectancy
                if (Utils.isEmpty(wE.id)) {
                    let wEId = Utils.generateUUID(5, 'id_');
                    wE.id = wEId;
                    wE.style.cursor = 'pointer';

                    this.tips.push(Utils.tooltip(wEId, a24n('Wait expectancy to reach agent')));
                }
            }
        }
        if (d.info.hasOwnProperty('queueinfo') && masMsgEl) {
            this.hasWaitMessage = true;
            var waitMessage = masMsgEl.querySelector('.waitMessage');
            if (waitMessage) {
                waitMessage.innerText = d.info.queueinfo;

                var waitOrder = masMsgEl.querySelector('.waitOrder');
                if (waitOrder) {
                    waitOrder.style.display = 'none';
                }
                var waitExpectancy = masMsgEl.querySelector('.waitExpectancy');
                if (waitExpectancy) {
                    waitExpectancy.style.display = 'none';
                }
            }
        }
    }

    /**
     * Executed on receiving LIVE agent message from SIP UA
     *
     * @param {Object} d
     * @private
     */
    onFsAgentMessage(d) {
        var mData = {};
        ('meta' in d ? (Array.isArray(d.meta) ? d.meta : [d.meta]) : []).forEach(m => {
            mData[m.category] = mData[m.category] || {};
            mData[m.category][m.name] = m.value;
        }, this);

        // we got INFO signal message (it should be after CONNECTED signal and chat window handles it but sometimes it comes before CONNECTED signal)
        if (Constants.MSG_CAT_INFO in mData) {
            this.chatInfoMsg = mData;
        }
        // we got CONNECTED signal
        else if (Constants.MSG_CAT_SIGNAL in mData && Constants.SIGNAL_CONNECTED in mData[Constants.MSG_CAT_SIGNAL]) {
            var chatInfoMsg = this.chatInfoMsg;
            this.fsUAPreserveOnClose = true;
            this.hide(true);

            // show chat window
            new this.chatWindowClass(Utils.applyIf({
                chatInfoMsg : chatInfoMsg,
                agent       : '',
                planName    : this.planName,
                planNumber  : this.planNumber
            }, this.winCfg || {}));
        }
    }

    /**
     * Request TURN info
     *
     * @param {Boolean} init Is this is initial call
     * @private
     */
    getWebRtcGetTurnInfo(init = false) {
        if (init) {
            console.log('LivePlans::Requesting TURN info...');

            // copy array of TURN url list from config
            this.turnRequestList = Asseco.config.TurnUrl.slice(0);
            this.turnIceServers = [];
        }

        // we have more TURN urls for request
        if (! Utils.isEmpty(this.turnRequestList)) {
            Connection.sendMessage(Constants.REQ_GET_WEBRTC_TURN, {
                url: this.turnRequestList.splice(0, 1)[0],
                post: 'service=turn&key=' + Asseco.config.TurnKey + '&username=' + Asseco.UUID
            }, this.onGetWebRtcGetTurnInfo, this);
        }
        // we have collected info from all TURN urls
        else {
            console.log('LivePlans::Collected all TURN info...');
            this.initFS.defer(250, this);
        }
    }

    /**
     * Executed after TURN info is fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetWebRtcGetTurnInfo(conn, msg) {
        var d = JSON.parse(msg.data);

        if (! Utils.isEmpty(d)) {
            console.log('LivePlans::TURN info fetched: ', d);
            this.turnIceServers.push({'urls': typeof d.uris[0] === 'string' ? d.uris[0] : ('turn:' + d.uris[0].turn), 'username': d.username, 'credential': d.password});
        }
        else {
            console.warn('LivePlans::Problem fetching TURN info');
        }

        // fetch next
        console.warn('LivePlans::Requesting next TURN info');
        this.getWebRtcGetTurnInfo.defer(250, this);
    }

    /**
     * Cancel call to plan (close web socket connection)
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} el Clicked HTML element
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onCancelCallPlan(e, el) {
        console.log('LivePlans::Issuing disconnect to plan');
        if (Connection.getStatus() === Connection.OPEN) {
            // Utils.toast(a24n('Disconnected'));

            // close window
            this.hide(true);
        }
        // else {
        //     Utils.toast(a24n('Not connected'));
        // }
    }

    /**
     * Error occured on invite, try again
     *
     * @param {Event} e ClickEvent
     * @param {HTMLElement} el Clicked HTML element
     * @private
     */
    // eslint-disable-next-line no-unused-vars
    onError(e, el) {
        console.log('LivePlans::try again calling');
        if (Asseco.fsUA) {
            console.log('LivePlans::Closing SIP UA...');
            Asseco.fsUA.stop();
            Asseco.fsUA = null;
        }

        this.showPlanMask(this.planName, a24n('Cancel'), this.onCancelCallPlan, this);
        this.getFSLoginData();
    }
}
LivePlans.prototype.xtype = 'LivePlans';
export default LivePlans;
