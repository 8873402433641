import Svgs from 'data/Svgs';
import Utils from 'util/Utils';
import Element from 'util/Element';
import LiveField from 'widgets/field/Field';
import LiveFilemanagerAuthAbstract from './../abstract';

//////////////////////////////////////////////
import LiveFilemanagerWindow from './../../window/Window';
//////////

/**
 * LIVE mToken Component (not available in Asseco namespace)
 *
 * Document authentication adapter
 *
 * @private
 */
class LiveFilemanagermToken extends LiveFilemanagerAuthAbstract {
    /**
     * constructor
     * @param {Object} config
     */
    constructor(config = {}) {
        // apply default config if not specified
        Utils.applyIf(config, {
            id    : 'asseco-mtoken-window',
            title : a24n('mToken')
        });

        // call the parent class' constructor
        super(config);

        Utils.apply(this, config);
    }

    /**
     * Init component
     *
     * @private
     */
    initComponent() {
        super.initComponent();

        // save prefix for elements ids
        this.pf = 'asseco-mtoken-';
    }

    /**
     * Called after component is rendered
     *
     * @private
     */
    afterRender() {
        super.afterRender();

        this.getFolderFiles();
    }

    /**
     * Load this component style (loaded css is added to head)
     *
     * @private
     */
    getStyle() {
        super.getStyle();
        require('./mToken.scss');
    }

    /**
     * Get documents and links for signing
     *
     * @private
     */
    getFolderFiles() {
        console.log('LiveFilemanagermToken::Fetching folder files (merge)');

        // change window size for loading message
        this.wHeight();

        // no tools for wait message
        this.setTools([]);

        this.setTitle(String.format(a24n('mToken - {0}'), LiveFilemanagerWindow.displayFolderName(this.folder.name)));

        super.getFolderFiles();
    }

    /**
     * Executed after documents are fetched
     *
     * @param {Connection} conn Reference to web socket connection
     * @param {Object} msg Message received on web socket
     * @private
     */
    onGetFolderFiles(conn, msg) {
        this.hideMask();

        var d = JSON.parse(msg.data),
            f = this.folder,
            c = f.conf;

        if (! window.matchMedia('(max-width: 700px)').matches) {
            this.setSize(Math.min(700, window.innerWidth - 100) + 'px', (window.innerHeight - 50) + 'px');
        }

        // list of links (required and optional)
        var links = [], lHtml = '', lUuid, linkReqC = [];
        if (! Utils.isEmpty(c) && ! Utils.isEmpty(c.links)) {
            if (typeof c.links === 'string') {
                c.links = JSON.parse(c.links);
            }
            links = c.links.sort((a, b) => (b.config.required_confirm ? 1 : 0) - (a.config.required_confirm ? 1 : 0));
        }
        links.forEach(l => {
            if (l.config.required_confirm) {
                lUuid = Utils.generateUUID(5, 'id_');
                linkReqC.push(lUuid);
                lHtml += '<div class="' + this.pf + 'link">' +
                            '<label class="mdl-checkbox mdl-js-checkbox" for="' + lUuid + '">' +
                                '<input type="checkbox" id="' + lUuid + '" class="mdl-checkbox__input">' +
                                '<span class="mdl-checkbox__label"><a class="asseco-link" href="' + l.config.url + '" target="_blank">' + l.name + '</a></span>' +
                            '</label>' +
                        '</div>';
            }
            else {
                lHtml += '<div class="' + this.pf + 'link"><a class="asseco-link ' + this.pf + 'link-nc" href="' + l.config.url + '" target="_blank">' + l.name + '</a></div>';
            }
        }, this);

        this.setContent(require('babel-loader!template-string-loader!./mTokenDocuments.html')({
            previewClass  : this.pf + 'docpreview',
            screenBtnIcon : Utils.getIconMarkup(this.docFullIcon),
            url           : Asseco.config.ChatServerUrl + '/server/chat-download.php?disposition=inline&downloadKey=' + d.data,
            links         : lHtml
        }));

        Array.prototype.slice.call(this.containerEl.querySelectorAll('label.mdl-checkbox')).forEach(c => {
            this.cHU(c);
            c.MaterialCheckbox.inputElement_.onclick = () => {
                this.getEl('#' + this.pf + 'signBtn').disabled = this.containerEl.querySelectorAll('.' + this.pf + 'link .mdl-checkbox input:checked').length < linkReqC.length;
            };
        }, this);

        // show tools for full screen and close window
        this.setTools([{
            icon: Svgs.FULL_SCREEN,
            scope: this,
            handler: () => {
                Element.fullScreen(this.getEl('iframe.' + this.pf + 'docpreview'));
            }
        }, {
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);

        this.setButtons([{
            id: this.pf + 'signBtn',
            text: a24n('Confirm'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
            icon: this.signIcon,
            scope: this,
            handler: this.confirm
        }, {
            text: a24n('Close'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);

        if (! Utils.isEmpty(linkReqC)) {
            this.getEl('#' + this.pf + 'signBtn').disabled = true;
        }
    }

    /**
     * Confirm documents with mToken
     *
     * @private
     */
    confirm() {
        // change window height
        this.wHeight(365);

        var pf = this.pf,
            tF = LiveField.getTextField;

        this.setContent(require('babel-loader!template-string-loader!./mTokenInput.html')({
            errTxtId : pf + 'errTxt',

            msg : a24n('Please enter token SN and OTP'),

            pass : tF({
                id       : pf + 'tokensn',
                label    : a24n('Token SN'),
                cls      : pf + 'fMiddle',
                required : true
            }),

            otp : tF({
                id       : pf + 'otp',
                label    : a24n('OTP'),
                cls      : pf + 'fMiddle',
                required : true
            })
        }));

        // register dynamic material design components
        ['tokensn', 'otp'].forEach(f => {
            this.cHU(this.getEl('#' + pf + f));
        }, this);

        // show tool for closing window
        this.setTools([{
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);

        this.setButtons([{
            id: pf + 'signBtn',
            text: a24n('Confirm'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnOkCls,
            icon: this.signIcon,
            scope: this,
            handler: this.doConfirm
        }, {
            text: a24n('Close'),
            cls: this.buttonsCls + ' mdl-button--raised ' + this.btnCloseCls,
            icon: Svgs.CLOSE,
            scope: this,
            handler: this.onClose
        }]);
    }

    /**
     * Make request for mToken confirm
     *
     * @private
     */
    doConfirm() {
        var els = {
            'tokensn' : '',
            'otp'     : ''
        };

        var req = true;

        // get reference to each el
        for (var prop in els) {
            els[prop] = this.getEl('#' + this.pf + prop + '-input');

            // check required
            if (['tokensn', 'otp'].indexOf(prop) > -1 && Utils.isEmpty(els[prop].value)) {
                req = false;
                els[prop].focus();
                els[prop].blur();
            }
        }

        // missing required fields
        if (! req) {
            return false;
        }

        // check if otp format id valid
        var otp = els.otp;
        if (otp.value.length !== 6 || ! /^[0-9]+$/.test(otp.value)) {
            this.getEl('#' + this.pf + 'otp span.mdl-textfield__error').textContent = a24n('Please enter valid OTP');
            otp.value = '';
            otp.focus();
            otp.blur();
            return;
        }

        console.log('LiveFilemanagermToken::doConfirm');

        // change window size for loading message
        this.wHeight();

        // no tools for wait message
        this.setTools([]);

        if (this.onSuccess) {
            this.onSuccess(a24n('Documents authorized'), this.folder);
        }

        this.hide(true);
    }
}
LiveFilemanagermToken.prototype.xtype = 'LiveFilemanagermToken';
export default LiveFilemanagermToken;
